// ============================================================
// RESERVE
// ============================================================
// .p-reserve
// --------------------------------------------------
.p-reserve {
	position: relative;
	margin-bottom: 80px;
	@include pc {
		margin-bottom: 180px; }

	// .p-reserve__ttl
	&__ttl {
		@include f(28);
		letter-spacing: ls(420);
		text-align: center;
		font-weight: 600;
		padding-left: 21px;
		position: relative;
		z-index: 1;
		@include pc {
			@include f(40);
			letter-spacing: ls(840);
			padding-left: 50px; } } }


// .p-rsv-modal
// --------------------------------------------------
.p-rsv-modal {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 101;
	overflow-y: scroll;
	display: none;
	padding: 70px percentage(25 / widthSp(base));
	@include pc {
		padding: 180px percentage(190 / widthPc(base)); }

	// .p-rsv-modal__cont
	&__cont {
		width: 100%;
		position: relative;
		background-color: cl(white);
		padding: 65px percentage(25 / 325);
		z-index: 9;
		@include pc {
			padding: 94px percentage(120 / 900) 90px; }

		h2 {
			position: relative;

			span {
				display: block; }

			.ja {
				@include f(20);
				letter-spacing: ls(120);
				font-weight: 600;
				@include pc {
					@include f(24); } }

			.en {
				@include FT-L;
				@include f(13);
				letter-spacing: ls(120);
				color: cl(gray-a);
				margin-top: 6px;
				@include pc {
					@include f(15);
					letter-spacing: ls(180);
					margin-top: 16px; } } } }

	// .p-rsv-modal__list
	&__list {
		margin-top: 46px;
		padding-left: 0;
		@include pc {
			margin-top: 72px; } }

	// .p-rsv-modal__item
	&__item {
		position: relative;
		padding-left: 45px;
		@include pc {
			padding-left: 82px; }

		&:not(:last-child) {
			padding-bottom: 40px;
			@include pc {
				padding-bottom: 70px; }
			&:after {
				content: '';
				width: 1px;
				height: 100%;
				background-color: cl(gray-c);
				position: absolute;
				top: 0;
				left: 17px;
				@include pc {
					left: 29px; } } }

		@for $i from 1 through 5 {
			&:nth-child(#{$i}) {
				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 34px;
					height: 34px;
					background: url('../img/reserve/flow_num0#{$i}.png') no-repeat;
					background-size: 100% 100%;
					@include pc {
						width: 58px;
						height: 58px; } } } } }

	// .p-rsv-modal__ttl
	&__ttl {
		@include fz(18,31.5);
		letter-spacing: ls(120);
		font-weight: 600;
		padding: 1px 0;
		@include pc {
			@include fz(20,42);
			padding: 8px 0; } }

	// .p-rsv-modal__read
	&__read {
		@include fz(14,28);
		letter-spacing: ls(60);
		margin-top: 10px;
		@include pc {
			margin-top: 5px; } }

	// .p-rsv-modal__close-btn
	&__close-btn {
		position: absolute;
		top: 16px;
		right: 16px;
		width: 33px;
		height: 33px;
		border-radius: 50%;
		border: 1px solid cl(gray-c);
		@include easing;
		@include pc {
			top: 60px;
			right: percentage(60 / 900);
			width: 56px;
			height: 56px;
			&:hover {
				background-color: cl(gray-c); } }

		.line {
			display: block;
			width: 14px;
			height: 2px;
			background-color: cl(black);
			@include center;
			@include pc {
				width: 24px;
				height: 2px; }

			&--l {
				transform: rotate(45deg); }

			&--r {
				transform: rotate(-45deg); } } }

	// .p-rsv-modal__overlay
	&__overlay {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background-color: rgba(cl(black), .8);
		z-index: 8;
		display: none; } }


// .p-rsvmdl-sec
// --------------------------------------------------
.p-rsvmdl-sec {
	+ .p-rsvmdl-sec {
		margin-top: 55px;
		@include pc {
			margin-top: 92px; } }

	// .p-rsvmdl-sec__txt
	&__txt {
		margin-top: 32px;
		@include pc {
			margin-top: 55px; }

		p {
			@include fz(14,28);
			letter-spacing: ls(60); }

		p + p {
			margin-top: 20px;
			@include pc {
				margin-top: 27px; } } } }


// .p-rsvmdl-lob
// --------------------------------------------------
.p-rsvmdl-lob {
	// .p-rsvmdl-lob__list
	&__list {
		margin-top: 0;
		@include pc {
			margin-top: 30px; } }

	// .p-rsvmdl-lob__item
	&__item {
		counter-increment: num;
		position: relative;
		padding: 32px 0 31px;
		border-bottom: 1px solid cl(gray-c);

		.num {
			@include Did;
			@include fz(14,16);
			letter-spacing: ls(60);
			color: cl(gray-c);
			font-weight: 600;
			position: relative;

			&::before {
				content: "No."counter(num,decimal-leading-zero); } }

		.name {
			@include fz(18,28);
			letter-spacing: ls(120);
			font-weight: 600;
			margin-top: 20px;
			@include pc {
				margin-top: 6px; } }

		.explanation {
			@include fz(14,28);
			letter-spacing: ls(60);
			margin-top: 10px;
			@include pc {
				margin-top: 8px; } }

		.price {
			@include fz(14,28);
			letter-spacing: ls(60);
			color: cl(gray-a);
			position: absolute;
			top: 32px;
			right: 0; } } }


// .p-rsv-choose
// --------------------------------------------------
.p-rsv-choose {
	padding-top: 70px;
	@include pc {
		padding-top: 130px; }

	// .p-rsv-choose__rsvbtn
	&__rsvbtn {
		width: percentage(340 / widthSp(base));
		margin: 0 0 0 auto;
		padding: 33px percentage(35 / widthSp(base)) 33px percentage(50 / widthSp(base));
		@include fz(16,21);
		letter-spacing: ls(460);
		text-align: left;
		display: flex;
		justify-content: left;
		border: 1px solid cl(gray-c);
		background-color: cl(gray-c);
		position: relative;
		-webkit-appearance: none;
		border-radius: 0;
		@include pc {
			width: percentage(1080 / widthPc(base));
			max-width: 1080px;
			margin: 0 auto;
			padding: 28px 0;
			@include fz(20,42);
			letter-spacing: ls(460);
			text-align: center;
			justify-content: center;
			background-color: cl(white);
			@include easing;

			&:hover {
				color: cl(white);
				background-color: cl(gray-c); } }

		@include mq320 {
			padding: 33px percentage(25 / widthSp(base)) 33px; } }

	// .p-rsv-choose__rsvbtn-ico
	&__rsvbtn-ico {
		display: block;
		width: 4px;
		height: 16px;
		position: absolute;
		top: 50%;
		right: percentage(40 / 340);
		transform: translateY(-50%) rotate(-90deg);
		@include pc {
			display: none; }

		svg {
			width: 100%;
			height: 100%;
			fill: cl(black-3); } }

	// .p-rsv-choose__alert
	&__alert {
		display: none;
		@include fz(16);
		letter-spacing: ls(120);
		text-align: center;
		margin-bottom: 20px;
		color: cl(red); } }


// .p-rsv-blend
// --------------------------------------------------
.p-rsv-blend {
	padding-top: 94px;
	@include pc {
		padding-top: 210px; }

	.p-reserve__ttl {
		@include f(28);
		@include pc {
			@include f(48);
			letter-spacing: ls(790); } }

	// .p-rsv-blend__inner
	&__inner {
		width: 100%;
		margin: 85px auto 0;
		background-color: cl(gray-f7);
		display: block;
		padding: 0 0 10px;
		@include pc {
			width: percentage(1248 / widthPc(base));
			max-width: 1248px;
			margin: -24px auto 0;
			padding: 115px percentage(84 / 1248) 110px; } }

	// .p-rsv-blend__item
	&__item {
		display: block;
		@include pc {
			width: 100%;
			display: flex;
			flex-flow: row-reverse nowrap; }

		+ .p-rsv-blend__item {
			@include pc {
				margin-top: 120px; } }

		&:first-child {
			.p-rsv-blend__thumb img {
				margin-top: -40px;
				@include pc {
					margin-top: 0; } } }

		&:nth-child(even) {
			flex-flow: row nowrap;

			.p-rsv-blend__thumb {
				margin-left: auto;
				@include pc {
					margin-left: 0; } }

			.p-rsv-blend__lead {
				right: auto;
				left: -16px;
				@include pc {
					left: auto;
					right: percentage(-3 / 560); } }

			.p-rsv-blend__body {
				@include pc {
					margin-right: 0;
					margin-left: percentage(64 / 1080); } } } }

	// .p-rsv-blend__thumb
	&__thumb {
		width: percentage(340 / widthSp(base));
		position: relative;
		@include pc {
			width: percentage(560 / 1080); } }

	// .p-rsv-blend__lead
	&__lead {
		@include FT-L;
		color: cl(white);
		@include fz(35,35);
		letter-spacing: ls(105);
		display: block;
		writing-mode: vertical-rl;
		text-align: right;
		position: absolute;
		width: auto;
		bottom: -40px;
		right: -16px;
		@include pc {
			@include fz(65,83);
			writing-mode: horizontal-tb;
			width: 110%;
			bottom: -40px;
			right: percentage(-3 / 560); } }

	// .p-rsv-blend__body
	&__body {
		width: 100%;
		padding: 45px percentage(35 / widthSp(base)) 70px;
		@include pc {
			width: percentage(456 / 1080);
			padding: 20px 0 0;
			margin-right: percentage(64 / 1080); } }

	// .p-rsv-blend__ttl
	&__ttl {
		position: relative;

		span {
			display: block; }

		.ja {
			@include f(20);
			letter-spacing: ls(120);
			font-weight: 600;
			@include pc {
				@include f(24); } }

		.en {
			@include FT-L;
			@include f(13);
			letter-spacing: ls(120);
			color: cl(gray-a);
			margin-top: 6px;
			@include pc {
				@include f(14);
				letter-spacing: ls(180); } } }

	// .p-rsv-blend__read
	&__read {
		@include fz(14,28);
		letter-spacing: ls(120);
		margin-top: 28px;
		margin-bottom: 30px;
		@include pc {
			margin-top: 42px;
			margin-bottom: 50px; }

		a {
			display: inline-block;
			border-bottom: 1px solid cl(text);
			@include easing;
			@include pc {
				&:hover {
					border-bottom: 1px solid transparent; } } } } }
