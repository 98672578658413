// ============================================================
// CLOCK
// ============================================================
// .c-clock
// --------------------------------------------------
.c-clock {
	@include FT-M;
	@include f(10);
	letter-spacing: ls(180);
	color: cl(gray-a);
	position: relative;
	padding-left: 24px;
	@include pc {
		padding-left: 28px; }

	&:before {
		content: '';
		width: 16px;
		height: 16px;
		border-radius: 50%;
		@include grad(60deg);
		position: absolute;
		top: calc(50% - 1px);
		left: 0px;
		transform: translateY(-50%);
		@include pc {
			left: 2px; } } }
