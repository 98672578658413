// ============================================================
// BLOG
// ============================================================
// .p-blog-atc
// --------------------------------------------------
.p-blog-atc {
	position: relative;

	.c-clock {
		position: absolute;
		top: 38px;
		bottom: auto;
		left: 12px;
		writing-mode: vertical-rl;
		padding-left: 0;
		padding-top: 28px;
		z-index: 1;
		@include pc {
			top: 200px;
			left: percentage(41 / widthPc(base)); }

		&:before {
			top: 0;
			left: 53%;
			transform: translateX(-50%); } }

	// .p-blog-atc__mv
	&__mv {
		width: 100%;
		margin-top: 75px;
		position: relative;
		@include pc {
			width: percentage(1180 / widthPc(base));
			margin-left: auto;
			margin-top: 45px; }

		&:before {
			content: '';
			width: percentage(220 / widthSp(base));
			height: 300px;
			@include grad(90deg);
			position: absolute;
			top: -203px;
			right: 0;
			z-index: -1;
			@include pc {
				width: percentage(500 / 1180); } } }

	// .p-blog-atc__txten
	&__txten {
		@include FT-B;
		@include f(35);
		letter-spacing: ls(660);
		color: cl(white);
		position: absolute;
		top: -53px;
		right: 9px;
		@include pc {
			@include f(54);
			letter-spacing: ls(780);
			top: -75px;
			right: 52px; } }

	// .p-blog-atc__img
	&__img {
		width: 100%;

		img {
			width: 100%;
			height: auto;
			// height: 211px
			object-fit: cover;
			@include pc {
 } } }				// height: 540px

	// .p-blog-atc__article
	&__article {
		position: relative;
		width: 100%;
		margin: 0 auto 100px;
		z-index: 1;
		overflow: hidden;
		@include pc {
			// width: percentage(920 / widthPc(base))
			// max-width: 920px
			margin: -190px auto 160px; }

		.c-share {
			margin: 0 auto;
			@include pc {
				margin: 0 percentage(260 / widthPc(base)) 0 auto; } } }

	// .p-blog-atc__inner
	&__inner {
		width: 100%;
		margin: 0 auto;
		padding: 25px percentage(35 / widthSp(base)) 63px;
		position: relative;
		background-color: cl(white);
		@include pc {
			width: percentage(920 / widthPc(base));
			max-width: 920px;
			padding: 60px percentage(80 / widthPc(base)) 135px; }

		&:before {
			display: none;
			@include pc {
				content: '';
				display: block;
				width: 50%;
				height: 100%;
				background-color: cl(white);
				position: absolute;
				top: 0;
				left: calc(100% - 180px);
				z-index: -1; } } }

	// .p-blog-atc__head
	&__head {}

	// .p-blog-atc__lead
	&__lead {
		display: flex;
		align-items: center;

		.cat {
			@include FT-B;
			@include f(15);
			letter-spacing: ls(60);
			padding-right: 9px;
			@include pc {
				@include f(17);
				padding-right: 13px; } }

		.date {
			@include Lao;
			@include f(12);
			letter-spacing: ls(60);
			color: cl(gray-a);
			padding-left: 9px;
			position: relative;
			padding-top: 2px;
			@include pc {
				@include f(13);
				padding-left: 13px; }

			&:before {
				content: '';
				display: block;
				width: 1px;
				height: 18px;
				background-color: cl(gray-c);
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%); } }

		.share {
			margin-left: auto;
			@include pc {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 160px;
				margin-left: 0;
				padding: 0 8px 20px;
				border-bottom: 1px solid cl(gray-c);
				position: absolute;
				top: 115px;
				right: -140px;
				transform: rotate(90deg); }

			&__head {
				display: none;
				@include pc {
					@include FT-B;
					@include fz(14,28);
					letter-spacing: ls(360);
					display: block; } }

			ul {
				font-size: 0; }

			li {
				display: inline-block;
				transform: rotate(0);
				@include pc {
					transform: rotate(-90deg); }

				+ li {
					margin-left: 7px; } }

			a {
				width: 28px;
				height: 28px;
				position: relative;
				@include easing;
				@include pc {
					&:hover {
						svg {
							fill: cl(black); } } } }

			svg {
				width: 23px;
				height: 23px;
				fill: cl(gray-c);
				@include center;
				@include easing; } } }

	// .p-blog-atc__ttl
	&__ttl {
		@include fz(22,39);
		letter-spacing: ls(120);
		font-weight: 500;
		margin-top: 13px;
		padding-bottom: 30px;
		border-bottom: 1px solid cl(gray-c);
		@include pc {
			@include fz(28,49);
			margin-top: 13px;
			padding-bottom: 0;
			border-bottom: none; } }

	// .p-blog-atc__body
	&__body {
		margin-top: 40px;
		@include pc {
			margin-top: 94px; }

		h2 {
			@include fz(18,32);
			font-weight: 600;
			letter-spacing: ls(120);
			margin-bottom: 28px;
			@include pc {
				@include fz(20,40);
				margin-bottom: 28px; } }

		h3 {
			@include fz(16,28);
			font-weight: 600;
			letter-spacing: ls(120);
			margin-bottom: 32px;
			@include pc {
				@include fz(16,32);
				margin-bottom: 30px; } }

		h4 {
			@include fz(14,25);
			font-weight: 600;
			letter-spacing: ls(120);
			margin-bottom: 32px;
			@include pc {
				@include fz(14,28);
				margin-bottom: 30px; } }

		p {
			@include fz(14,28);
			letter-spacing: ls(120);
			margin-bottom: 10px;

			+ p {
				margin-top: 35px; } }

		a {
			@include fz(14,28);
			letter-spacing: ls(120);
			color: cl(text);
			display: block;
			position: relative;
			text-decoration: underline;
			@include alpha();

			&[target="_blank"] {
				display: inline-block;

				&:after {
					content: '';
					display: inline-block;
					width: 12px;
					height: 12px;
					background-image: url('../img/common/ico_blank.svg');
					background-size: 100% 100%;
					background-repeat: no-repeat;
					margin-left: 10px; } } }

		.note {
			@include fz(11,20);
			letter-spacing: ls(120);
			color: cl(gray-9);
			margin-top: 10px;
			@include pc {
				@include fz(12,24); } }

		figure {
			width: 100%;
			margin: 0 auto 35px;
			@include pc {
				width: percentage(640 / 760);
				margin: 0 auto 60px; }

			+ figure {
				margin-top: 35px;
				@include pc {
					margin-top: 60px; } } }

		figcaption {
			@include fz(11,20);
			letter-spacing: ls(120);
			text-align: right;
			color: cl(gray-9);
			margin-top: 5px;
			@include pc {
				@include fz(12,24); } }

		ol,ul {
			width: 100%;
			margin: 28px auto;
			padding-left: 3em;
			@include pc {
				width: percentage(720 / 760);
				margin: 45px auto;
				padding-left: 2em; }

			li {
				@include fz(14,28);
				letter-spacing: ls(120);
				position: relative;

				+ li {
					margin-top: 15px; } } }

		ol {
			counter-reset: num;

			li {

				&:before {
					content: counter(num, decimal-leading-zero);
					counter-increment: num 1;
					display: block;
					color: cl(text);
					@include FT-B;
					@include f(16);
					letter-spacing: ls(120);
					position: absolute;
					top: 2px;
					left: -2em;
					@include pc {
						@include f(14);
						top: 3px; } } } }

		ul {
			li {
				&:before {
					content: '';
					display: block;
					width: 6px;
					height: 6px;
					border-radius: 50%;
					background-color: cl(text);
					position: absolute;
					top: 11px;
					left: -1.7em;
					@include pc {
						left: -1.5em; } } } }

		blockquote {
			width: 100%;
			margin: 35px auto;
			padding: 10px 1em;
			border-left: 3px solid cl(gray-c);
			background-color: cl(gray-f7);
			@include pc {
				margin: 45px auto;
				padding: 10px 2em; }

			cite {
				@include fz(11,20);
				letter-spacing: ls(120);
				@include pc {
					@include fz(12,24); } } }

		table {
			border-collapse: collapse;
			@include fz(14,28);
			letter-spacing: ls(120);
			text-align: left; }

		tbody {
			tr {
				border: 1px solid cl(gray-c); }
			th {
				border: 1px solid cl(gray-c);
				padding: 5px 10px; }
			td {
				border: 1px solid cl(gray-c);
				padding: 5px 10px; } }

		.wp-block-table.is-style-stripes tbody tr:nth-child(2n+1) {
			background-color: cl(gray-f7); }

		// pタグ 特大
		.has-huge-font-size {
			@include fz(20,35);
			letter-spacing: ls(120);
			@include pc {
				@include fz(22,44); } }

		// pタグ 大
		.has-large-font-size {
			@include fz(18,32);
			letter-spacing: ls(120);
			@include pc {
				@include fz(20,40); } }

		// pタグ 中
		.has-medium-font-size {
			@include fz(16,28);
			letter-spacing: ls(120);
			@include pc {
				@include fz(16,32); } }

		// pタグ 小
		.has-small-font-size {
			@include fz(11,20);
			letter-spacing: ls(120);
			@include pc {
				@include fz(12,24); } }
		// border
		.wp-block-separator {
			width: 100%;
			border: none;
			border-top: 1px solid cl(gray-c);
			margin: 52px 0;
			@include pc {
				margin: 105px 0; } }

		// box
		.has-background {
			width: 100%;
			margin: 0 auto;
			background-color: cl(gray-f7);
			padding: 42px percentage(28 / 305) 34px;
			@include pc {
				width: percentage(740 / 760);
				padding: 50px percentage(60 / 760) 40px; } } }

	// .p-blog-atc__backidx
	&__backidx {
		width: 100%;
		margin: 0 auto 100px;
		@include pc {
			margin: -1px auto 160px;
			border-top: 1px solid cl(gray-c);
			border-bottom: 1px solid cl(gray-c); }

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 30px;
			background-color: cl(black-3);
			@include easing(1.2);
			@include pc {
				padding: 41px;
				background-color: cl(white);
				&:hover {
					background-color: cl(black-3);

					span {
						color: cl(white); } } } }

		svg {
			width: 23px;
			height: 23px;
			fill: cl(gray-a);
			margin-right: 14px;
			@include pc {
				margin-right: 22px; } }

		span {
			@include FT-B;
			@include f(16);
			letter-spacing: ls(280);
			color: cl(white);
			@include pc {
				@include f(16);
				letter-spacing: ls(280);
				color: cl(text); } } } }


