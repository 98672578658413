// ============================================================
// CARDS
// ============================================================
// .c-cards
// --------------------------------------------------
.c-cards {
	$this: &;

	padding-left: 0;

	// .c-cards__item
	&__item {
		background-color: cl(white);
		counter-increment: num; }

	// .c-cards__head
	&__head {
		border-bottom: 4px solid cl(gray-f4);
		position: relative; }

	// .c-cards__headinner
	&__headinner {
		display: flex;
		padding: 25px 30px;

		.num {
			width: percentage(70 / 740);
			@include Did;
			@include fz(20);
			font-weight: 500;
			margin-top: 7px; }

		.wrap {
			width: percentage(670 / 740); }

		.ttl {
			@include fz(32);
			letter-spacing: ls(120);
			font-weight: 400; }

		.read {
			@include fz(15,32);
			letter-spacing: ls(120);
			margin-top: 4px; } }

	// .c-cards__body
	&__body {
		padding: 45px 25px;
		display: flex; }

	// .c-cards__bodyttl
	&__bodyttl {
		@include FT-L;
		@include fz(16);
		letter-spacing: ls(430);
		color: cl(gray-a); }

	// .c-cards__graph
	&__graph {
		width: percentage(302 / 750);
		margin: 1px 0 0 percentage(54 / 750); }

	// .c-cards__details
	&__details {
		width: percentage(238 / 750);
		margin: 10px 0 0 percentage(58 / 750); }

	// .c-cards__details-item
	&__details-item {

		+ #{$this}__details-item {
			margin-top: 35px; }

		.ttl {
			@include fz(14);
			letter-spacing: ls(120);
			display: block;
			padding: 3px 6px;
			border-bottom: 1px solid cl(gray-c); }

		.wrap {
			display: flex;
			align-items: center;
			margin-top: 17px;
			padding: 0 8px; }

		.img {
			display: inline-block;
			width: percentage(48 / 222);
			padding: 2px;
			border-radius: 50%;
			border: 1px solid cl(gray-c);
			margin-right: 15px; }

		.txt {
			display: inline-block;
			width: percentage(172 / 222);
			@include fz(16);
			letter-spacing: ls(120); } }

	// .c-cards__details-ex
	&__details-ex {
		width: percentage(170 / 480);
		margin: auto 0 0 percentage(30 / 480);

		.txt {
			@include fz(14,28);
			letter-spacing: ls(120);
			position: relative;
			padding-left: 25px;

			&::before {
				content: '';
				width: 12px;
				height: 12px;
				border-radius: 50%;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				background-color: #abc9f5; }

			&:nth-child(2)::before {
				background-color: #ceddf5; }

			&:nth-child(3)::before {
				background-color: #e9edf5; } } } }












