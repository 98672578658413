// ============================================================
// ABOUT
// ============================================================
// .p-about
// --------------------------------------------------
.p-about {
	margin-bottom: 80px;
	@include pc {
		margin-bottom: 200px; }

	.c-info {
		margin-top: 70px;
		@include pc {
			margin-top: 180px; } }

	.l-contact {
		margin-top: 60px;
		@include pc {
			margin-top: 136px; } } }


// .p-about-sec
// --------------------------------------------------
.p-about-sec {
	position: relative;
	z-index: 1;
	margin-top: 43px;

	&:nth-of-type(odd) {
		.p-about-sec__inner {
			@include pc {
				padding-left: percentage(60 / widthPc(base)); } }

		.p-about-sec__thumb {
			margin-right: auto;
			@include pc {
				margin-right: 0;
				margin-left: auto; } }

		.p-about-sec__body {
			@include pc {
				float: left; } }

		.p-about-sec__lead {
			@include pc {
				text-align: right; } } }

	&:nth-of-type(even) {
		.p-about-sec__inner {
			@include pc {
				padding-right: percentage(60 / widthPc(base)); } }

		.p-about-sec__thumb {
			margin-left: auto;
			@include pc {
				margin-left: 0; } }

		.p-about-sec__body {
			@include pc {
				float: right; } }

		.p-about-sec__lead {
			text-align: right;
			@include pc {
				text-align: left; } } }

	&:before {
		content: '';
		@include grad(90deg);
		position: absolute;
		bottom: 0;
		z-index: -1; }

	// .p-about-sec__inner
	&__inner {
		width: 100%;
		margin: 0 auto;
		// padding: 0 percentage(35 / widthSp(base))
		@include pc {
			width: percentage(980 / widthPc(base));
			max-width: 980px;
			padding: 0;
			@include clearfix; } }

	// .p-about-sec__body
	&__body {
		width: 100%;
		position: relative;
		padding: 0 percentage(35 / widthSp(base));
		z-index: 1;
		@include pc {
			width: percentage(265 / 920);
			padding: 43px 0 0; } }

	// .p-about-sec__ttl
	&__ttl {
		position: relative;
		padding-bottom: 18px;
		@include pc {
			padding-bottom: 20px; }

		&:after {
			content: '';
			display: block;
			width: 32px;
			height: 1px;
			background-color: cl(gray-a);
			position: absolute;
			bottom: 0;
			left: 0; }

		span {
			display: block; }

		.ja {
			@include f(20);
			letter-spacing: ls(120);
			font-weight: 600;
			@include pc {
				@include f(24); } }

		.en {
			@include FT-L;
			@include f(13);
			letter-spacing: ls(120);
			color: cl(gray-a);
			margin-top: 6px;
			@include pc {
				@include f(14);
				letter-spacing: ls(180); } } }

	// .p-about-sec__read
	&__read {
		@include fz(14,28);
		letter-spacing: ls(120);
		margin-top: 28px;
		@include pc {
			margin-top: 38px; } }

	// .p-about-sec__thumb
	&__thumb {
		width: percentage(340 / widthSp(base));
		margin-top: 28px;
		@include pc {
			width: percentage(560 / 920);
			margin-top: 0; } }

	// .p-about-sec__lead
	&__lead {
		@include FT-L;
		@include fz(39,43);
		letter-spacing: ls(90);
		color: cl(gray-e9);
		position: absolute;
		@include pc {
			@include fz(65,83);
			letter-spacing: ls(120); } }

	// .p-about-sec--01
	&--01 {
		margin-top: 43px;
		@include pc {
			margin-top: 140px; }

		&:before {
			width: percentage(280 / widthSp(base));
			height: calc(100% - 574px);
			left: 0;
			@include pc {
				width: percentage(880 / widthPc(base));
				height: calc(100% - 216px);
				left: auto;
				right: 0; } }

		.p-about-sec__thumb {
			padding-bottom: 95px;
			@include pc {
				padding-bottom: 175px; } }

		.p-about-sec__lead {
			left: percentage(35 / widthSp(base));
			bottom: 31px;
			@include pc {
				bottom: 48px;
				left: auto;
				right: percentage(52 / 920); } } }

	// .p-about-sec--02
	&--02 {
		margin-top: 65px;
		@include pc {
			margin-top: 140px; }

		&:before {
			width: percentage(360 / widthSp(base));
			height: calc(100% - 350px);
			right: 0;
			bottom: 22px;
			@include pc {
				width: percentage(1030 / widthPc(base));
				height: calc(100% - 152px);
				left: 0;
				right: auto;
				bottom: 0; } }

		.p-about-sec__thumb {
			padding-bottom: 63px;
			@include pc {
				padding-bottom: 179px; } }

		.p-about-sec__lead {
			bottom: 0;
			right: percentage(30 / widthSp(base));
			@include pc {
				left: percentage(25 / 920);
				right: 0;
				bottom: 50px; } } }

	// .p-about-sec--03
	&--03 {
		margin-top: 57px;
		@include pc {
			margin-top: 120px; }

		&:before {
			width: percentage(360 / widthSp(base));
			height: calc(100% - 425px);
			right: 0;
			@include pc {
				width: percentage(640 / widthPc(base));
				height: calc(100% - 160px); } }

		.p-about-sec__thumb {
			padding-bottom: 53px;
			@include pc {
				padding-bottom: 179px; } }

		.p-about-sec__lead {
			bottom: 32px;
			right: percentage(35 / widthSp(base));
			@include pc {
				bottom: 130px;
				right: percentage(140 / widthPc(base)); } } }

	// .p-about-sec--04
	&--04 {
		margin-top: 65px;
		@include pc {
			margin-top: 120px; }

		&:before {
			width: percentage(170 / widthSp(base));
			height: calc(100% - 312px);
			left: 0;
			@include pc {
				width: percentage(770 / widthPc(base));
				height: calc(100% - 210px);
				left: percentage(100 / widthPc(base)); } }

		.p-about-sec__thumb {
			padding-bottom: 53px;
			@include pc {
				padding-bottom: 179px; } }

		.p-about-sec__lead {
			bottom: 32px;
			left: percentage(35 / widthSp(base));
			@include pc {
				bottom: 132px;
				left: percentage(25 / 920); } } } }


// .p-about-guide
// --------------------------------------------------
.p-about-guide {
	width: 100%;
	margin: 0 auto;
	padding: 65px percentage(35 / widthSp(base)) 60px;
	@include pc {
		width: percentage(860 / widthPc(base));
		max-width: 860px;
		padding: 155px 0 130px; }

	h2 {
		margin-bottom: 37px;
		@include pc {
			margin-bottom: 42px; }

		span {
			display: block;
			text-align: center;
			letter-spacing: ls(120); }

		.ja {
			@include f(20);
			font-weight: 600;
			@include pc {
				@include f(24); } }

		.en {
			@include FT-L;
			@include fz(13);
			margin-top: 6px;
			letter-spacing: ls(120);
			@include pc {
				@include fz(14); } } }

	p {
		@include fz(14,28);
		letter-spacing: ls(60);
		@include pc {
			text-align: center; }

		+ p {
			margin-top: 28px; } } }


// .p-about-rsv
// --------------------------------------------------
.p-about-rsv {
	width: 100%;
	margin: 0 auto;
	@include bgCover('../img/about/rsv_bg.jpg');
	position: relative;
	@include pc {
		width: percentage(1080 / widthPc(base));
		max-width: 1080px; }

	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(cl(black), .6);
		@include center; }

	// .p-about-rsv__inner
	&__inner {
		position: relative;
		width: 100%;
		margin: 0 auto;
		z-index: 1;
		padding: 50px percentage(35 / widthSp(base)) 60px;
		@include pc {
			padding: 72px percentage(50 / 1080) 88px; }

		h2 {
			@include FT-B;
			@include f(37);
			letter-spacing: ls(590);
			text-align: center;
			color: cl(gray-a);
			padding-left: percentage(16 / 305);
			@include pc {
				@include fz(48,80);
				letter-spacing: ls(780);
				padding-left: percentage(35 / 980); } }

		p {
			@include fz(15,30);
			letter-spacing: ls(120);
			color: cl(white);
			text-align: center;
			margin-top: 24px;
			@include pc {
				@include fz(16,32);
				margin-top: 15px; } } }

	// .p-about-rsv__link
	&__link {
		display: block;
		margin-top: 32px;
		@include pc {
			display: flex;
			margin-top: 60px; }

		.btn {
			width: 100%;
			height: 50px;
			border: 1px solid cl(white);
			@include easing;
			@include alpha(.6);
			@include pc {
				width: percentage(470 / 980);
				height: 100px;
				border: 2px solid cl(white); }

			+ .btn {
				margin-top: 24px;
				@include pc {
					margin-top: 0;
					margin-left: percentage(40 / 980); } } }

		a {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			@include fz(15,21);
			letter-spacing: ls(480);
			color: cl(white);
			@include pc {
				@include fz(16,32);
				letter-spacing: ls(520);
				font-weight: 600; } } } }





