// ============================================================
// RATE ANSWER
// ============================================================
// .p-rate-answer
// --------------------------------------------------
.p-rate-answer {}


// .p-ra
// --------------------------------------------------
.p-ra {
	padding: 110px percentage(80 / widthPc(base)) 190px; }


// .p-ra-cards
// --------------------------------------------------
.p-ra-cards {
	width: 100%;
	padding-top: 120px;
	border-top: 1px solid cl(gray-c);
	margin-top: 40px;
	display: flex;
	flex-flow: row wrap;

	&__item {
		width: percentage(530 / 1120);

		&:nth-child(even) {
			margin-left: percentage(60 / 1120); }

		&:nth-child(n+3) {
			margin-top: 60px; } } }












