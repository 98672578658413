// ============================================================
// BUTTON
// ============================================================
// .c-btn
// --------------------------------------------------
.c-btn {
	+ .c-btn {
		margin-top: 24px; }

	a {
		width: 100%;
		height: 50px;
		@include fz(14,28);
		letter-spacing: ls(520);
		text-align: center;
		color: cl(white);
		background-color: cl(text);
		display: flex;
		align-items: center;
		justify-content: center;
		@include easing;
		@include pc {
			width: 300px;
			height: 48px;

			&:hover {
				color: cl(text);
				background-color: cl(gray-c); } } }

	&.f-en {
		a {
			@include FT-B;
			@include fz(17,28);
			letter-spacing: ls(370); } } }

