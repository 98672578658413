// ============================================================
// HEAD LINE
// ============================================================
// .c-hdl
// --------------------------------------------------
.c-hdl {
	position: relative;
	z-index: 1;
	// word-break: break-word

	&:before {
		content: '';
		width: 65px;
		height: 65px;
		position: absolute;
		top: -13px;
		left: -20px;
		z-index: -1;
		@include pc {
			width: 110px;
			height: 110px;
			top: 0;
			left: 0; } }

	span {
		display: block;
		font-weight: 400; }

	.en {
		@include FT-M;
		color: cl(black); }

	.ja {
		@include f(14);
		letter-spacing: ls(120);
		color: cl(gray-a);
		margin-top: 12px;
		@include pc {
			margin-top: 5px; } }

	&--row1 {
		.en {
			@include fz(32,40);
			letter-spacing: ls(690);
			@include pc {
				@include fzvw(44,55);
				letter-spacing: ls(800); }
			@include wide {
				@include fz(44,55); } } }

	&--row2 {
		.en {
			@include fz(25,38);
			letter-spacing: ls(465);
			@include pc {
				@include fzvw(38,48);
				letter-spacing: ls(490); }
			@include wide {
				@include fz(38,48); } } } }
