// ============================================================
// BASE
// ============================================================
*, *:before, *:after {
	-webkit-box-sizing: border-box;
	box-sizing: border-box; }


// html, body
// --------------------------------------------------
html, body {
	width: 100%;
	height: 100%;
	margin: 0; }

// Modal fixed body
html.is-locked {
	height: 100%;
	overflow: hidden; }

body {
	width: 100%;
	@include fz(14);
	@include JPfont;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	color: cl(text);
	letter-spacing: ls(60);
	position: relative;
	@include pc {
		@include fz(16); }
	//For Analysis tag
	> iframe,
	> img,
	> div:not([class]) > iframe,
	> div:not([class]) > img {
		display: none; } }

img {
	max-width: 100%;
	height: auto;
	pointer-events: none; }

li {
	> ul, > ol {
		margin-bottom: 0; } }

a {
	color: cl(text);
	text-decoration: none;
	cursor: pointer;
	display: block; }

ul,li {
	list-style-type: none; }

figure {
	text-align: center; }

img,
video {
	max-width: 100%;
	width: 100%;
	vertical-align: top; }

picture {
	display: block; }

button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	padding: 0;
	appearance: none; }

button,
input,
textarea {
	border-radius: 0; }

h1, h2, h3, h4, h5, h6,
ul, ol, dl,
blockquote, p, address,
hr,
table,
fieldset, figure,
pre {
	margin-top: 0;
	margin-bottom: 0; }

ul,li,dl,dt,dd {
	padding: 0; }
