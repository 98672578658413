// 0
//------------------------------------------------------------
.u-m0 {
	margin: 0; }



// common
//------------------------------------------------------------
@for $i from 0 through 100 {
	.u-mt#{$i} {
		margin-top: #{$i}px !important; } }

@for $i from 0 through 100 {
	.u-mb#{$i} {
		margin-bottom: #{$i}px !important; } }

@for $i from 0 through 100 {
	.u-ml#{$i} {
		margin-left: #{$i}px !important; } }

@for $i from 0 through 100 {
	.u-mr#{$i} {
		margin-right: #{$i}px !important; } }



// SP
//------------------------------------------------------------
@for $i from 0 through 100 {
	.u-mt#{$i}-sp {
		@include sp {
			margin-top: #{$i}px !important; } } }

@for $i from 0 through 100 {
	.u-mb#{$i}-sp {
		@include sp {
			margin-bottom: #{$i}px !important; } } }

@for $i from 0 through 100 {
	.u-ml#{$i}-sp {
		@include sp {
			margin-left: #{$i}px !important; } } }

@for $i from 0 through 100 {
	.u-mr#{$i}-sp {
		@include sp {
			margin-right: #{$i}px !important; } } }



// PC
//------------------------------------------------------------
@for $i from 0 through 100 {
	.u-mt#{$i}-pc {
		@include pc {
			margin-top: #{$i}px !important; } } }

@for $i from 0 through 100 {
	.u-mb#{$i}-pc {
		@include pc {
			margin-bottom: #{$i}px !important; } } }

@for $i from 0 through 100 {
	.u-ml#{$i}-pc {
		@include pc {
			margin-left: #{$i}px !important; } } }

@for $i from 0 through 100 {
	.u-mr#{$i}-pc {
		@include pc {
			margin-right: #{$i}px !important; } } }



// em
//------------------------------------------------------------
@for $i from 0 through 10 {
	.u-mt-1_#{$i}em {
		margin-top: 1+($i/10)+em !important; }

	.u-mb-1_#{$i}em {
		margin-bottom: 1+($i/10)+em !important; }

	.u-ml-1_#{$i}em {
		margin-left: 1+($i/10)+em !important; }

	.u-mr-1_#{$i}em {
		margin-right: 1+($i/10)+em !important; } }
