// .c-cdt-card
// --------------------------------------------------
.c-cdt-card {
	$this: &;
	background-color: cl(white);
	margin-top: 70px;
	margin-right: percentage(35 / widthSp(base));
	padding: 34px percentage(33 / widthSp(base)) 50px;
	position: relative;
	@include pc {
		margin-top: 80px;
		margin-right: auto;
		padding: 75px 60px 60px; }

	input {
		@include fz(16,30);
		@include JPfont;
		color: cl(text);
		padding: 12px;
		@include pc {
			padding: 13px; } }

	// .c-cdt-card__nth
	&__nth {
		position: absolute;
		width: calc(100% - 70px);
		height: 50px;
		top: -20px;
		left: percentage(35 / widthSp(base));
		@include pc {
			width: calc(100% - 60px);
			height: 80px;
			top: -30px;
			left: 30px; }

		p {
			@include fz(50,50);
			@include Lao;
			color: #c6c6c6;
			background: -webkit-linear-gradient(-90deg, #c6c6c6, #fafafa);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			@include pc {
				@include fz(80,80); } } }

	// .c-cdt-card__item
	&__item {
		display: block;
		margin-top: 28px;
		@include pc {
			display: flex;
			align-items: center;
			margin-top: 40px; } }

	// .c-cdt-card__term
	&__term {
		width: 100%;
		@include fz(16,30);
		letter-spacing: ls(120);
		@include pc {
			width: percentage(130 / 570);
			margin-right: percentage(30 / 570); } }

	// .c-cdt-card__input
	&__input {
		width: 100%;
		@include JPfont;
		@include fz(14,28);
		letter-spacing: ls(120);
		margin-top: 10px;
		@include pc {
			width: percentage(410 / 570);
			@include fz(16,32);
			margin-top: 0; }

		// .c-cdt-card__input--sm
		&--sm {
			width: 200px;
			@include pc {
				width: 120px; } }

		// .c-cdt-card__input--md
		&--md {
			width: 200px;
			@include pc {
				width: 200px; } }

		#{$this}--completion & {
			color: cl(red); }

		input[type="radio"] {
			position: absolute;
			overflow: hidden;
			clip: rect(0, 0, 0, 0);
			margin: -1px;
			padding: 0;
			width: 1px;
			height: 1px;
			border: 0;

			&:checked + label {
				&::before {
					background: cl(white); }

				&::after {
					content: '';
					display: block;
					width: 12px;
					height: 12px;
					border-radius: 50%;
					background: cl(black-3);
					position: absolute;
					top: 4px;
					left: 4px; } } }

		label {
			position: relative;
			margin-right: 28px;
			padding: 2px 0 2px 32px;
			cursor: pointer;

			&::before {
				content: '';
				display: block;
				width: 20px;
				height: 20px;
				border: 1px solid cl(gray-9);
				border-radius: 50%;
				position: absolute;
				top: 0;
				left: 0; } } }


	// .c-cdt-card__name
	&__name {}

	// .c-cdt-card__des
	&__des {}

	// .c-cdt-card__select
	&__select {
		position: relative;
		@include pc {
 }			// width: percentage(200 / 410)

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 4px 6px 4px;
			border-color: transparent transparent cl(gray-9) transparent;
			top: 50%;
			right: 8px;
			z-index: 1; }

		&::before {
			transform: translateY(-5px); }

		&::after {
			transform: translateY(5px) rotate(180deg); }

		select {
			width: 100%;
			@include fz(16,32);
			@include JPfont;
			color: cl(text);
			background-color: cl(white);
			display: block;
			box-shadow: none;
			border-radius: 0;
			border: none;
			border-bottom: 1px solid cl(gray-c);
			padding: 5px 0;
			position: relative;
			outline: none;
			appearance: none;
			cursor: pointer;
			@include alpha();

			&::-ms-expand {
				display: none; } } }

	// .c-cdt-card__blendid
	&__blendid {
		#{$this}__input {
			line-height: 0; }

		input {
			border: none;
			background-color: cl(gray-f7); } }

	// .c-cdt-card__removebtn
	&__removebtn {
		@include fz(14);
		letter-spacing: ls(480);
		padding: 8px 6px;
		display: block;
		border-bottom: 1px solid cl(gray-c);
		cursor: pointer;
		width: 149px;
		margin: 53px 0 0 auto;
		position: relative;
		@include alpha();
		@include pc {
			width: 156px;
			padding: 4px 8px;
			margin: 80px 0 0 auto; }

		&::before,
		&::after {
			content: '';
			width: 11px;
			height: 1px;
			background-color: cl(black-3);
			position: absolute;
			top: 50%;
			right: 8px; }

		&::before {
			transform: translateY(-50%) rotate(45deg); }
		&::after {
			transform: translateY(-50%) rotate(-45deg); } } }
