// ============================================================
// 404
// ============================================================
// .p-error
// --------------------------------------------------
.p-error {
	width: 100%;
	padding: 50px percentage(35 / widthSp(base));
	@include pc {
		width: percentage(980 / widthPc(base));
		max-width: 980px;
		margin: 0 auto;
		padding: 50px 0; }

	h1 {
		@include fz(22,39);
		letter-spacing: ls(120);
		font-weight: 600;
		@include pc {
			@include fz(28,49); } }

	p {
		@include fz(15,30);
		letter-spacing: ls(120);
		margin-top: 24px;
		@include pc {
			@include fz(16,32);
			margin-top: 15px; } }

	.c-btn {
		margin-top: 50px;
		@include pc {
			margin-top: 100px; } } }

