// ============================================================
// GLOBAL NAV
// ============================================================
// .l-gnav
// --------------------------------------------------
.l-gnav {
	display: none;
	@include pc {
		display: block;
		margin-top: -68px; }

	// .l-gnav__list
	&__list {
		height: 68px;
		display: flex;
		align-items: center; }

	// .l-gnav__item
	&__item {
		position: relative;

		+ li {
			margin-left: 28px; }

		a {
			@include FT-B;
			@include f(15);
			letter-spacing: ls(300);
			padding: 3px;
			@include alpha(.6); }

		&.is-active {
			a {
				color: cl(gray-a);
				pointer-events: none; }

			&:before {
				content: '';
				display: block;
				width: 32px;
				height: 32px;
				border-radius: 50%;
				@include grad(90deg);
				position: absolute;
				top: 50%;
				left: 0;
				right: 0;
				transform: translateY(-50%);
				margin: auto;
				z-index: -1;
				opacity: 1; } } } }


