// ============================================================
// RESERVE FORM
// ============================================================
// .p-rsvform
// --------------------------------------------------
.p-rsvform {

	// .p-rsvform__contents
	&__contents {
		display: block;
		@include pc {
			display: flex;
			flex-flow: row-reverse nowrap;
			justify-content: flex-end; } }

	// .p-rsvform__btnwrap
	&__btnwrap {
		width: 100%;
		display: block;
		margin: 50px auto 0;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		@include pc {
			flex-flow: row-reverse nowrap;
			margin: 100px auto 0;
			padding: 0; }

		input {
			-webkit-appearance: none;
			border-radius: 0; } }

	// .p-rsvform__btn
	&__btn {
		width: percentage(305 / 375);
		height: 50px;
		margin: 0 auto;
		@include fz(14,21);
		letter-spacing: ls(480);
		text-align: center;
		@include pc {
			width: 300px;
			height: 60px;
			margin: auto; }

		// .p-rsvform__btn--mg
		&--mg {
			margin-top: 32px;
			@include pc {
				margin-top: 0;
				margin-right: percentage(90 / 690); } }

		a {
			width: 100%;
			height: 100%;
			color: cl(text);
			display: flex;
			align-items: center;
			justify-content: center;
			@include easing; }


		// .btn--wh
		&--wh {
			background-color: cl(white);
			border: 1px solid cl(gray-c);

			a {
				@include pc {
					&:hover {
						color: cl(text);
						background-color: cl(gray-c); } } } }

		// .btn--bk
		&--bk {
			background-color: cl(text);
			border: none;
			color: cl(white);
			cursor: pointer;
			@include easing;
			@include pc {
				&:hover {
					color: cl(text);
					background-color: cl(gray-c); } }

			a {
				color: cl(white);

				@include pc {
					&:hover {
						color: cl(text);
						background-color: cl(gray-c); } } } } }

	.remove_btn {
		cursor: pointer; } }


// .p-rsvform-head
// --------------------------------------------------
.p-rsvform-head {
	padding: 28px percentage(33 / widthSp(base)) 0;
	@include pc {
		padding: 50px percentage(150 / widthPc(base)) 110px; }

	// .p-rsvform-head__hdl
	&__hdl {
		@include fz(28,42);
		letter-spacing: ls(290);
		font-weight: 600;
		@include pc {
			@include fz(40,50); }

		.small {
			@include FT-L;
			display: block;
			@include fz(15,19);
			letter-spacing: ls(520);
			color: cl(gray-a);
			margin-top: 15px;
			@include pc {
				@include fz(16,21); } } } }


// .p-rsvform-step
// --------------------------------------------------
.p-rsvform-step {
	margin: 60px auto 100px;
	padding: 0 percentage(33 / widthSp(base));
	@include pc {
		margin: 60px 0 0 percentage(50 / widthPc(base));
		padding: 0; }

	// .p-rsvform-step__inner
	&__inner {
		@include pc {
			padding-top: 40px;
			position: sticky;
			top: 0; } }

	// .p-rsvform-step__list
	&__list {
		position: relative;

		&::before {
			content: '';
			width: 1px;
			height: calc(100% - 22px);
			background-color: cl(gray-c);
			@include center;
			left: 3px;
			right: auto; } }

	// .p-rsvform-step__item
	&__item {
		@include fz(14,21);
		letter-spacing: ls(120);
		color: cl(gray-c);
		position: relative;
		padding-left: 22px;

		&.is-current {
			color: cl(black-3);
			&::before {
				background-color: cl(black-3); } }

		+ li {
			margin-top: 25px; }

		&::before {
			content: '';
			width: 7px;
			height: 7px;
			border-radius: 50%;
			background-color: cl(gray-c);
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%); }


		.num {
			@include FT-B;
			@include fz(12,21);
			letter-spacing: ls(150);
			margin-right: 5px; } } }


// .p-rsvform-main
// --------------------------------------------------
.p-rsvform-main {
	width: 100%;
	background-color: cl(gray-f7);
	padding: 60px 0 60px;
	@include pc {
		width: percentage(920 / widthPc(base));
		padding: 90px 80px 90px 150px; }

	// .p-rsvform-main__head
	&__head {
		padding: 0 percentage(35 / widthSp(base));
		@include pc {
			padding: 0; }

		.hdl {
			@include fz(18,32);
			letter-spacing: ls(320);
			font-weight: 600;
			padding-bottom: 8px;
			border-bottom: 1px solid cl(gray-c);
			@include pc {
				@include fz(20,42);
				padding-bottom: 5px; } }

		.read {
			@include fz(14,28);
			letter-spacing: ls(120);
			margin-top: 28px;
			@include pc {
				@include fz(14,28);
				margin-top: 40px; } }

		.comptxt {
			@include fz(18,36);
			letter-spacing: ls(120);
			font-weight: 500;
			margin-top: 26px;
			@include pc {
				margin-top: 40px; } }

		.note {
			@include fz(16,24);
			letter-spacing: ls(120);
			color: cl(gray-a);
			padding-left: 1em;
			text-indent: -1em;
			margin-top: 16px;
			@include pc {
				margin-top: 22px; } } }

	// .p-rsvform-main__modalbtn
	&__modalbtn {
		width: 100%;
		@include f(14);
		letter-spacing: ls(500);
		padding: 3px 0;
		position: relative;
		margin-top: 24px;
		@include pc {
			width: percentage(360 / 690);
			padding: 2px 0;
			margin-top: 32px;
			cursor: pointer;

			&:hover {
				.icon {
					background-color: cl(gray-c); } } }

		&:before {
			content: '';
			width: calc(100% - 245px);
			height: 1px;
			background-color: cl(gray-c);
			position: absolute;
			top: 50%;
			right: 44px;
			transform: translateY(-50%);
			@include pc {
				width: calc(100% - 250px);
				right: 50px; } }

		.icon {
			display: block;
			width: 28px;
			height: 28px;
			border-radius: 50%;
			border: 1px solid cl(gray-c);
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			@include easing;
			@include pc {
				width: 25px;
				height: 25px; }

			&:before,
			&:after {
				content: '';
				background-color: cl(black);
				position: absolute;
				top: 50%;
				right: 0;
				left: 0;
				margin: auto;
				transform: translateY(-50%); }

			&:before {
				width: 10px;
				height: 1px;
				@include pc {
					width: 9px; } }

			&:after {
				width: 1px;
				height: 10px;
				@include pc {
					height: 9px; } } } }

	// .p-rsvform-main--beans
	&--beans {
		.p-rsvform-customerinfo {
			margin-top: 70px;
			@include pc {
				margin-top: 75px; } } }

	// .p-rsvform-main--blend
	&--blend {
		.c-form__unit,
		.p-rsvform-customerinfo__item {
			margin-top: 35px;
			@include pc {
				margin-top: 40px; } } } }


// .p-rsvform-customerinfo
// --------------------------------------------------
.p-rsvform-customerinfo {
	$this: &;

	// .p-rsvform-customerinfo__item
	&__item {
		padding: 0 percentage(33 / widthSp(base));
		display: block;
		@include pc {
			padding: 0;
			display: flex;
			flex-flow: row wrap;
			align-items: center; }

		// .p-rsvform-customerinfo__item--first
		&--first {
			margin-top: 33px;
			@include pc {
				margin-top: 55px; } }

		+ #{$this}__item {
			margin-top: 35px;
			@include pc {
				margin-top: 40px; } }

		label {
			width: 100%;
			display: block;
			@include fz(16,30);
			letter-spacing: ls(120);
			@include pc {
				// margin-right: percentage(15 / 690)
 }				// display: inline-block

			.p-rsvform--conditions & {
				@include pc {
					// width: percentage(155 / 690)
					width: 165px; } } }

		.c-cdt-card__term {
			@include pc {
				width: 165px;
				// width: percentage(130 / 570)
 } }				// margin-right: percentage(30 / 570)

		.c-cdt-card__input {
			@include pc {
				width: 410px;
 } } }				// width: percentage(410 / 570)

	// .p-rsvform-customerinfo__input
	&__input {

		input {
			width: 100%;
			background-color: cl(white);
			border: none;
			@include fz(16,30);
			@include JPfont;
			letter-spacing: ls(120);
			padding: 14px;
			margin-top: 14px;
			@include pc {
				// width: percentage(360 / 690)
				width: 360px;
				padding: 13px;
				margin-top: 0; }

			&::placeholder {
				color: #8f8f8f; } }

		// .p-rsvform-customerinfo__input--gray
		&--gray {
			input {
				background-color: cl(gray-f7); } } }

	// .p-rsvform-customerinfo__date
	&__date {

		input {
			width: 200px;
			background-color: cl(white);
			border: none;
			@include fz(16,30);
			@include JPfont;
			letter-spacing: ls(120);
			color: cl(text);
			padding: 14px;
			cursor: pointer;
			margin-top: 14px;
			@include pc {
				width: 220px;
				padding: 13px;
				margin-top: 0; } }

		// .p-rsvform-customerinfo__date--gray
		&--gray {
			input {
				background-color: cl(gray-f7); } } }

	// .p-rsvform-customerinfo__select
	&__select {

		select {
			width: 100%;
			@include fz(16,30);
			@include JPfont;
			letter-spacing: ls(120);
			color: cl(text);
			box-shadow: none;
			border-radius: 0;
			border: none;
			padding: 14px;
			position: relative;
			outline: none;
			appearance: none;
			cursor: pointer;
			margin-top: 14px;
			background-color: cl(white);
			@include pc {
				padding: 13px;
				margin-top: 0; }

			&::-ms-expand {
				display: none; } } }

	// .p-rsvform-customerinfo__selectbox
	&__selectbox {
		width: 200px;
		position: relative;
		display: inline-block;
		@include pc {
			width: 220px; }

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 4px 6px 4px;
			border-color: transparent transparent cl(gray-9) transparent;
			top: 50%;
			right: 8px;
			z-index: 1; }

		&::before {
			transform: translateY(-5px); }

		&::after {
			transform: translateY(5px) rotate(180deg); }

		// .p-rsvform-customerinfo__selectbox--gray
		&--gray {
			select {
				background-color: cl(gray-f7); } } }

	// .p-rsvform-customerinfo__txt
	&__txt {
		margin-top: 40px;
		padding: 0 percentage(33 / widthSp(base));
		@include pc {
			margin-top: 55px;
			padding: 0; }

		p {
			@include fz(14,28);
			letter-spacing: ls(120); } } }


// .p-rsvform--completion
// --------------------------------------------------
.p-rsvform--completion {

	// .p-rsvform__btn
	.p-rsvform__btn {
		margin-top: 75px;
		margin-bottom: 55px;
		@include pc {
			margin-top: 120px;
			margin-bottom: 100px; } } }


// .p-rsvform-modal
// --------------------------------------------------
.p-rsvform-modal {

	// .p-rsvform-modal__img
	&__img {
		width: 100%;
		margin: 40px auto 0;
		@include pc {
			margin: 70px auto 0; } } }


// .p-rsvform-customtab
// --------------------------------------------------
.p-rsvform-customtab {
	margin-top: 55px;

	.c-form__unit {
		padding: 0; }

	// .p-rsvform-customtab__ttl
	&__ttl {
		@include fz(16,30);
		letter-spacing: ls(120);
		font-weight: 600;
		text-align: center; }

	// .p-rsvform-customtab__list
	&__list {
		display: flex;
		margin-top: 26px; }

	// .p-rsvform-customtab__item
	&__item {
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		@include fz(14,21);
		letter-spacing: ls(50);
		text-align: center;
		background-color: cl(gray-c);
		color: cl(gray-6);
		padding: 15px;
		cursor: pointer;
		@include alpha();
		@include pc {
			@include fz(15,30); }

		&.is-active {
			background-color: cl(white);
			color: cl(balck);
			pointer-events: none; } }

	.p-rsvform-customerinfo__item,
	.p-rsvform-customerinfo__txt {
		padding: 0; }

	.p-rsvform__btnwrap .p-rsvform__btn {
		width: 100%;
		@include pc {
			width: 300px; } } }


// .p-rsvform-custom
// --------------------------------------------------
.p-rsvform-custom {
	background-color: cl(white);
	display: none;

	&.is-active {
		display: block; }

	.p-rsvform--confirmation & {
		background-color: cl(gray-f7);
		@include pc {
			background-color: cl(white); } }

	// .p-rsvform-custom__inner
	&__inner {
		padding: 34px percentage(33 / widthSp(base)) 50px;
		@include pc {
			padding: 65px percentage(60 / 690); }

		.p-rsvform--confirmation & {
			padding: 0 percentage(33 / widthSp(base));
			@include pc {
				padding: 65px percentage(60 / 690); } } }

	// .p-rsvform-custom__head
	&__head {

		.hdl {
			@include fz(18,32);
			letter-spacing: ls(200);
			font-weight: 600;
			padding-bottom: 8px;
			border-bottom: 1px solid cl(gray-c);
			@include pc {
				@include fz(16,28);
				padding-bottom: 18px; } }

		.read {
			@include fz(14,28);
			letter-spacing: ls(120);
			margin-top: 28px;
			@include pc {
				margin-top: 40px; } } } }


// .p-rsvform-customchoose
// --------------------------------------------------
.p-rsvform-customchoose {
	$this: &;

	// .p-rsvform-customchoose__modalbtn
	&__modalbtn {
		width: 100%;
		margin: 30px auto 0;
		@include fz(14,21);
		letter-spacing: ls(280);
		text-align: center;
		padding: 14px 48px 14px 14px;
		border: 1px solid cl(gray-c);
		position: relative;
		cursor: pointer;
		@include easing;
		@include pc {
			width: percentage(420 / 570);
			margin: 55px auto 0;
			@include fz(14,28);
			padding: 15px;
			&:hover {
				background-color: cl(gray-c);

				.icon {
					background-color: cl(white); } } }

		.icon {
			display: block;
			width: 28px;
			height: 28px;
			border-radius: 50%;
			border: 1px solid cl(gray-c);
			position: absolute;
			top: 50%;
			right: 14px;
			transform: translateY(-50%);
			@include easing;
			@include pc {
				width: 25px;
				height: 25px;
				right: 16px; }

			&:before,
			&:after {
				content: '';
				background-color: cl(black);
				position: absolute;
				top: 50%;
				right: 0;
				left: 0;
				margin: auto;
				transform: translateY(-50%); }

			&:before {
				width: 10px;
				height: 1px;
				@include pc {
					width: 9px; } }

			&:after {
				width: 1px;
				height: 10px;
				@include pc {
					height: 9px; } } } }

	// .p-rsvform-customchoose__item
	&__item {
		+ #{$this}__item {
			margin-top: 46px;
			@include pc {
				margin-top: 60px; } }

		// .p-rsvform-customchoose__item--first
		&--first {
			margin-top: 40px;
			@include pc {
				margin-top: 67px; } } }

	// .p-rsvform-customchoose__wrap
	&__wrap {
		+ #{$this}__wrap {
			margin-top: 55px; }

		.c-form__unit {
			margin-top: 28px; } }

	// .p-rsvform-customchoose__txt
	&__txt {
		margin-top: 40px;
		@include pc {
			margin-top: 55px; }

		p {
			@include fz(14,28);
			letter-spacing: ls(120);
			color: cl(red);

			&.small {
				@include fz(12,18);
				color: cl(gray-9);
				margin-top: 10px; } } } }


// .p-rsvform-customtrout
.p-rsvform-customtrout {
	margin: 50px auto;
	@include pc {
		margin: 90px auto; }

	.p-rsvform-custom__head {
		margin-bottom: 35px; }

	.c-form__unit {
		+ .c-form__unit {
			margin-top: 30px; } }

	.p-rsvform-custom__input--exlg {
		width: 270px;
		@include pc {
			max-width: none;
			width: 405px; } }

	// .p-rsvform-customtrout__modalbtn
	&__modalbtn {
		width: 28px;
		height: 28px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: -40px;
		@include pc {
			width: 25px;
			height: 25px;
			cursor: pointer;
			right: -65px;

			&:hover {
				.icon {
					background-color: cl(gray-c); } } }

		.icon {
			display: block;
			width: 28px;
			height: 28px;
			border-radius: 50%;
			border: 1px solid cl(gray-c);
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			@include easing;
			@include pc {
				width: 25px;
				height: 25px; }

			&:before,
			&:after {
				content: '';
				background-color: cl(black);
				position: absolute;
				top: 50%;
				right: 0;
				left: 0;
				margin: auto;
				transform: translateY(-50%); }

			&:before {
				width: 10px;
				height: 1px;
				@include pc {
					width: 9px; } }

			&:after {
				width: 1px;
				height: 10px;
				@include pc {
					height: 9px; } } } } }




