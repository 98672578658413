// ============================================================
// FOOTER
// ============================================================
// .l-footer
// --------------------------------------------------
.l-footer {
	width: 100%;
	margin: 0 auto;
	@include pc;

	// .l-footer__inner
	&__inner {
		display: flex;
		flex-direction: column-reverse;
		padding: 0 percentage(7.5 / widthSp(base));
		@include pc {
			flex-direction: row;
			padding: 0; } }

	// .l-footer__thumb
	&__thumb {
		width: 100%;
		margin-top: 15px;
		@include pc {
			width: percentage(800 / 1280);
			margin-top: 0; }

		img {
			@include pc {
				height: 450px;
				object-fit: cover; } } }

	// .l-footer__body
	&__body {
		width: 100%;
		margin: 0 auto;
		@include grad(180deg);
		padding: 40px 22px 50px 22px;
		position: relative;
		@include pc {
			width: percentage(448 / 1280);
			// width: calc(100% - 16px)
			// margin: 0 calc(100% - 16px)
			margin: 0 0 0 percentage(16 / 1280);
			padding: 70px 30px 35px 58px; } }

	// .l-footer__nav
	&__nav {

		ul {}

		li {
			+ li {
				margin-top: 3px;
				@include pc {
					margin-top: 11px; } } }

		a {
			@include FT-B;
			@include f(15);
			letter-spacing: ls(280);
			display: inline-block;
			padding: 5px;
			@include easing();
			@include pc {
				&:hover {
						color: cl(gray-a); } }

			&.is-active {
				color: cl(gray-a);
				pointer-events: none; } } }

	// .l-footer__sns
	&__sns {
		display: flex;
		margin-top: 36px;
		padding-left: 5px;
		@include pc {
			justify-content: flex-end;
			margin-top: 90px;
			padding-left: 0; }

		li {
			+ li {
				margin-left: 13px;
				@include pc {
					margin-left: 16px; } } }

		a {
			width: 40px;
			height: 40px;
			position: relative;
			@include easing;
			border-radius: 50%;
			border: 1px solid cl(gray-c);
			@include pc {
				width: 46px;
				height: 46px;

				&:hover {
					border: 1px solid cl(black);
					svg {
						fill: cl(black); } } } }

		svg {
			width: 20px;
			height: 20px;
			fill: cl(gray-c);
			@include center;
			@include easing;
			@include pc {
				width: 25px;
				height: 25px; } } }

	// .l-footer__pagetop
	&__pagetop {
		position: absolute;
		top: 100px;
		right: -31px;
		transform: rotate(-90deg);
		padding-right: 36px;
		@include alpha(.6);
		@include pc {
			top: 145px;
			padding-right: 70px; }

		&:after {
			content: '';
			width: 29px;
			height: 1px;
			background-color: cl(gray-a);
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			@include pc {
				width: 60px; } }

		a {
			@include FT-B;
			@include f(13);
			letter-spacing: ls(250);
			color: cl(gray-a);
			padding: 5px;
			display: inline-block; } }

	// .l-footer__lower
	&__lower {
		padding: 10px 0;
		@include pc {
			padding: 20px 0; }
		p {
			@include FT-L;
			@include f(11);
			letter-spacing: ls(180);
			color: cl(gray-a);
			text-align: center; } } }


// .l-footer-other
// --------------------------------------------------
.l-footer-other {

	// .l-footer-other__inner
	&__inner {
		padding: 25px percentage(80 / widthPc(base));
		background-color: cl(black-3);
		display: flex;
		align-items: center;
		justify-content: space-between; }

	// .l-footer-other__sitelink
	&__sitelink {
		@include FT-B;
		@include f(14);
		letter-spacing: ls(240);
		color: cl(gray-a);
		@include alpha(); }

	// .l-footer-other__copyright
	&__copyright {
		@include FT-L;
		@include f(11);
		letter-spacing: ls(180);
		color: cl(gray-a); } }


// .l-footer-rsv
// --------------------------------------------------
.l-footer-rsv {

	// .l-footer-rsv__inner
	&__inner {
		padding: 25px percentage(80 / widthPc(base));
		text-align: center; }

	// .l-footer-rsv__copyright
	&__copyright {
		@include FT-L;
		@include f(11);
		letter-spacing: ls(180);
		color: cl(gray-a); } }

