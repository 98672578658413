// ============================================================
// ICON
// ============================================================
// blank
// --------------------------------------------------
.ico-blank {
	display: inline-block;
	width: 12px;
	height: 12px;
	background-image: url('../img/common/ico_blank.svg');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	margin-left: 10px; }



