// ============================================================
// KEYFRAMES
// ============================================================
// scroll
// --------------------------------------------------
@keyframes scroll {
	0% {
		transform: scale(1,1);
		transform-origin: center bottom; }
	20% {
		transform: scale(1,0);
		transform-origin: center bottom; }
	30% {
		transform: scale(1,0);
		transform-origin: center top; }
	70% {
		transform: scale(1,1);
		transform-origin: center top; }
	100% {
		transform: scale(1,1);
		transform-origin: center top; } }


// kv-nav
// --------------------------------------------------
@keyframes kv-nav {
	0% {
		width: 0;
		opacity: 0; }
	100% {
		width: 60px;
		opacity: 1; } }


// msg-slide
// --------------------------------------------------
@keyframes msg-slide-progress {
	0% {
		width: 0; }
	100% {
		width: 100%; } }


