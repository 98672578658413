// ============================================================
// Align
// ============================================================
// 左右・中央に配置します
.u-align-left {
	display: block !important;
	margin-left: 0 !important;
	margin-right: auto !important; }

.u-align-center {
	display: block !important;
	margin-right: auto !important;
	margin-left: auto !important; }

.u-align-right {
	display: block !important;
	margin-left: auto !important;
	margin-right: 0 !important; }
