// ============================================================
// BEANS ANSWER
// ============================================================
// .p-beans-answer
// --------------------------------------------------
.p-beans-answer {}


// .p-ba
// --------------------------------------------------
.p-ba {
	padding: 110px percentage(80 / widthPc(base)) 190px;

	// .p-ba__contents
	&__contents {
		display: flex; } }


// .p-ba-anchor
// --------------------------------------------------
.p-ba-anchor {
	$this: &;

	width: percentage(300 / 1120);
	margin-right: percentage(20 / 1120);

	// .p-ba-anchor__inner
	&__inner {
		@include pc {
			padding-top: 40px;
			position: sticky;
			top: 0; } }

	// .p-ba-anchor__list
	&__list {}

	// .p-ba-anchor__item
	&__item {

		+ #{$this}__item {
			margin-top: 10px; }

		a {
			@include fz(14);
			letter-spacing: ls(120);
			color: cl(gray-c);

			&.is-current {
				color: cl(text); } }

		.num {
			@include Did;
			@include fz(14);
			letter-spacing: ls(120);
			font-weight: 600;
			display: inline-block; } } }


// .p-ba-main
// --------------------------------------------------
.p-ba-main {
	width: percentage(800 / 1120);
	padding-top: 120px;
	border-top: 1px solid cl(gray-c);
	margin-top: 40px;


	.c-cards__item {
		+ .c-cards__item {
			margin-top: 160px; } } }









