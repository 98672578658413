// ============================================================
// CONTACT
// ============================================================
.l-contact {
	width: 100%;
	margin: 0 auto;
	@include pc {
		width: percentage(1248 / widthPc(base)); }

	// .l-contact__head
	&__head {
		margin-bottom: 47px;
		@include pc {
			margin-bottom: 68px; }

		.c-hdl {
			padding: 32px 0 0;
			text-align: center;
			@include pc {
				padding: 33px 0 10px; }

			&:before {
				background: url('../img/common/apb/C-100.png') no-repeat;
				background-size: 100% 100%;
				@include center;
				width: 76px;
				height: 76px;
				@include pc {
					width: 110px;
					height: 110px; } }

			.en {
				@include fz(32,40);
				letter-spacing: ls(710);
				margin-left: 23px;
				@include pc {
					@include fzvw(42,53);
					letter-spacing: ls(520);
					margin-left: 25px; }
				@include wide {
					@include fz(42,53); } } } }

	// .l-contact__inner
	&__inner {
		padding: 0 percentage(35 / widthSp(base));
		@include pc {
			display: flex;
			border-top: 1px solid cl(black-3);
			border-bottom: 1px solid cl(black-3);
			padding: 0; } }

	// .l-contact__unit
	&__unit {
		width: 100%;
		position: relative;
		background-color: cl(gray-f7);
		@include pc {
			width: percentage(624 / 1248);
			background-color: cl(white); }

		+ .l-contact__unit {
			margin-top: 16px;
			@include pc {
				margin-top: 0; }
			&:before {
				content: '';
				display: none;
				width: 1px;
				height: calc(100% - 40px);
				background: cl(black-3);
				@include center;
				left: 0;
				right: auto;
				@include pc {
					display: block; } } }

		a {
			padding: 68px 20px 14px;
			position: relative;
			@include easing;
			@include pc {
				padding: 75px 20px 25px;
				&:hover {
					&::before {
						opacity: 1; } } }

			&::before {
				content: '';
				display: none;
				opacity: 0;
				width: calc(100% - 14px);
				height: calc(100% - 14px);
				background-color: cl(gray-f7);
				@include center;
				@include easing;
				@include pc {
					display: block; } } }


		.c-hdl {
			text-align: center;
			position: relative;
			z-index: 1;
			@include pc {
				padding: 33px 0 0; }

			&:before {
				position: absolute;
				top: -20px;
				left: 0;
				right: 0;
				margin: auto;
				@include pc {
					@include center; } }

			.en {
				@include fz(32,40);
				letter-spacing: ls(670);
				margin-left: 25px;
				@include pc {
					@include fzvw(42,53);
					letter-spacing: ls(520);
					margin-left: 25px; }
				@include wide {
					@include fz(42,53); } }

			.ja {
				margin-top: 10px;
				@include pc {
					margin-top: 15px; } }

			&.l-contact__ttl--mail {
				&:before {
					background: url('../img/common/apb/M-100.png') no-repeat;
					background-size: 100% 100%;
					width: 76px;
					height: 76px;
					@include pc {
						width: 110px;
						height: 110px; } } }

			&.l-contact__ttl--tel {
				&:before {
					background: url('../img/common/apb/T-100.png') no-repeat;
					background-size: 100% 100%;
					width: 76px;
					height: 76px;
					@include pc {
						width: 110px;
						height: 110px; } } } }

		p {
			@include FT-L;
			@include f(17);
			letter-spacing: ls(50);
			text-align: center;
			margin-top: 32px;
			position: relative;
			z-index: 1;
			@include pc {
				margin-top: 72px; } } } }

