// ============================================================
// TOP
// ============================================================
// .p-top
// --------------------------------------------------
.p-top {
	margin-bottom: 80px;
	@include pc {
		margin-bottom: 200px; }

	&:before {
		content: '';
		width: 50%;
		height: 100vh;
		// height: 680px
		@include grad(-180deg);
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;
		@include pc {
			width: percentage(220 / widthPc(base));
			height: 656px;
			left: 0;
			right: auto; } }

	.c-info {
		margin-top: 80px;
		@include pc {
			margin-top: 100px; } }

	.l-contact {
		margin-top: 60px;
		@include pc {
			margin-top: 136px; } } }



// .p-top-kv
// --------------------------------------------------
.p-top-kv {
	width: calc(100% - 35px);
	position: relative;
	margin: 45px auto 0 0;
	// padding: 0 percentage(100 / widthPc(base))
	@include pc {
		width: percentage(1080 / widthPc(base));
		max-width: 1080px;
		margin: 0 auto; }

	// .p-top-kv__hdl1
	&__hdl1 {
		width: percentage(260 / 340);
		max-width: 260px;
		position: absolute;
		left: 35px;
		bottom: 35px;
		z-index: 5;
		@include pc {
			max-width: none;
			width: percentage(401 / 1080);
			position: absolute;
			left: percentage(50 / 1080);
			bottom: percentage(60 / 560); } }

	// .p-top-kv__slide
	&__slide {
		width: 100%;
		height: calc(100vh - 170px);
		box-shadow: -2px 2px 20px 2px rgba(cl(black), .2);
		@include pc {
			height: 100%; }

		picture, img {
			object-fit: cover;
			height: 100%; } }

	.c-clock {
		position: absolute;
		bottom: -34px;
		left: 35px;
		@include pc {
			top: percentage(40 / 560);
			bottom: auto;
			left: auto;
			right: -52px;
			writing-mode: vertical-rl;
			padding-left: 0;
			padding-top: 20px;

			&:before {
				top: 0; } } }

	// .p-top-kv__scroll
	&__scroll {
		@include FT-B;
		@include f(0);
		letter-spacing: ls(250);
		color: cl(gray-a);
		position: absolute;
		width: 1px;
		height: 80px;
		bottom: 128px;
		right: -18px;
		@include pc {
			@include f(13);
			width: 20px;
			height: 134px;
			top: calc(50% - 10px);
			bottom: auto;
			left: percentage(-60 / 1080);
			right: auto; }

		&:before {
			content: '';
			display: block;
			position: absolute;
			width: 1px;
			height: 80px;
			background-color: cl(gray-a);
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			animation: scroll 2s 1s infinite;
			@include pc {
				height: 60px; } }

		span {
			display: none;
			@include pc {
				display: block;
				@include FT-B;
				@include f(13);
				letter-spacing: ls(250);
				color: cl(gray-a);
				position: absolute;
				width: 100%;
				height: auto;
				top: 0;
				bottom: auto;
				left: 0;
				right: 0;
				transform: rotate(90deg); } } } }


// .p-top-slnav
// --------------------------------------------------
.p-top-slnav {
	display: none;
	@include pc {
		display: block;
		position: absolute;
		right: percentage(-58 / 1080);
		bottom: percentage(52 / 560);
		z-index: 1; }

	li {
		position: relative;
		padding-left: 67px;
		// cursor: pointer

		+ li {
			margin-top: 8px; }

		&:before {
			content: '';
			width: 0;
			height: 1px;
			background-color: cl(black);
			position: absolute;
			top: calc(50% + 1px);
			left: 0;
			transform: translateY(-50%);
			// opacity: 0
 }			// +easing(1.5)

		&.is-active {

			&:before {
				animation: kv-nav .7s .3s forwards;
				// width: 60px
 }				// opacity: 1

			span {
				color: cl(black); } } }

	span {
		@include f(12);
		letter-spacing: ls(300);
		color: cl(gray-c);
		padding: 2px;
		display: block;
		@include easing(1); } }



// .p-top-msg
// --------------------------------------------------
.p-top-msg {
	width: 100%;
	margin: 0 auto;
	padding: 110px 0 0;
	position: relative;
	z-index: 2;
	@include pc {
		padding: 120px 0 0; }

	&:before {
		content: '';
		width: percentage(220 / widthSp(base));
		height: 358px;
		// height: calc(358em / 14)
		@include grad(90deg);
		position: absolute;
		top: 345px;
		right: 0;
		z-index: -1;
		@include pc {
			width: percentage(910 / widthPc(base));
			height: 480px;
			top: 358px; } }

	// .p-top-msg__inner
	&__inner {
		width: 100%;
		margin: 0 auto;
		@include pc {
			width: percentage(980 / widthPc(base));
			max-width: 980px;
			padding: 0; } }

	// .p-top-msg__body
	&__body {
		margin-bottom: 80px;
		padding: 0 percentage(35 / widthSp(base));
		@include pc {
			margin-bottom: 255px;
			padding: 0 percentage(33 / 980); } }

	.c-hdl {
		@include pc {
			padding: 25px 0 0 35px; }

		&:before {
			background: url('../img/common/apb/M-120.png') no-repeat;
			background-size: 100% 100%; } }

	// .p-top-msg__read
	&__read {
		@include fz(16,32);
		letter-spacing: ls(60);
		position: relative;
		z-index: 1;
		margin-top: 34px;
		@include pc {
			@include fz(16,40);
			margin-top: 56px;
			padding-left: percentage(117 / 914); } } }


// .p-top-msgslide
// --------------------------------------------------
.p-top-msgslide {
	padding-right: percentage(35 / widthSp(base));
	position: relative;
	@include pc {
		padding-right: 0; }

	// .p-top-msgslide__container
	&__container {
		box-shadow: -2px 2px 20px 2px rgba(cl(black), .2); }

	.swiper-container {
		overflow: visible; }

	.swiper-pagination {
		width: 126px !important;
		right: -6px !important;
		left: auto !important;
		bottom: -34px !important;
		@include pc {
			width: 156px !important;
			bottom: -26px !important; } }

	.swiper-pagination-bullet {
		width: 30px;
		height: 4px;
		display: inline-block;
		border-radius: 0;
		background: cl(gray-e9);
		opacity: 1;
		margin: 0 6px !important;
		@include pc {
			width: 30px; }

		&::before {
			content: '';
			width: 100%;
			height: 100%;
			background: cl(black);
			display: none; } }

	.swiper-pagination-bullet-active {
		width: 30px;
		height: 4px;
		position: relative;
		@include pc {
			width: 40px; }

		&::before {
			display: block;
			animation: msg-slide-progress 4s linear forwards; } } }


// .p-top-about
// --------------------------------------------------
.p-top-about {
	padding-top: 84px;
	position: relative;
	z-index: 1;
	@include pc {
		padding-top: 53px;
		padding-bottom: 90px; }

	&:before {
		content: '';
		width: percentage(275 / widthSp(base));
		height: 249px;
		// height: percentage(249 / 475)
		@include grad(90deg);
		position: absolute;
		top: 105px;
		left: 0;
		z-index: -1;
		@include pc {
			width: percentage(370 / widthPc(base));
			height: 150px; } }

	// .p-top-about__inner
	&__inner {
		width: 100%;
		margin: 0 auto;
		padding: 0 percentage(35 / widthSp(base));
		display: block;
		@include pc {
			width: percentage(980 / widthPc(base));
			max-width: 980px;
			padding: 0;
			display: flex; } }

	.c-hdl {
		@include pc {
			padding: 25px 0 0 40px; }

		&:before {
			background: url('../img/common/apb/A-120.png') no-repeat;
			background-size: 100% 100%; } }

	// .p-top-about__body
	&__body {
		@include pc {
			margin: 30px 0 0 percentage(105 / 920); } }

	// .p-top-about__read
	&__read {
		@include fz(14,28);
		letter-spacing: ls(120);
		margin-top: 41px;
		margin-bottom: 34px;
		@include pc {
			margin-top: 0;
			margin-bottom: 50px; } }

	// .p-top-about__link
	&__link {
		display: inline-block;

		a {
			@include FT-B;
			@include f(15);
			letter-spacing: ls(450);
			padding: 12px 0;
			position: relative;
			@include easing();
			@include pc {
				padding: 8px 0;
				&:hover {
					color: cl(gray-a);

					&:before {
						background-color: cl(gray-a); } } }

			&:before {
				content: '';
				display: block;
				width: calc(100% - 5px);
				height: 1px;
				background-color: cl(black);
				position: absolute;
				bottom: 0;
				left: 0;
				@include easing(); } } } }


// .p-top-rsv
// --------------------------------------------------
.p-top-rsv {
	width: 100%;
	margin: 90px auto 0;
	position: relative;
	padding-bottom: 70px;
	z-index: 1;
	overflow: hidden;
	@include pc {
		width: percentage(1080 / widthPc(base));
		max-width: 1080px;
		margin: 90px auto 0;
		padding-bottom: 70px;
		overflow: visible; }

	&:before {
		display: none;
		@include pc {
			display: block;
			content: '';
			width: percentage(360 / 1080);
			height: 100%;
			@include grad(90deg);
			position: absolute;
			top: 0;
			right: 0;
			z-index: 2; } }

	&:after {
		display: none;
		@include pc {
			display: block;
			content: 'STEP';
			@include FT-L;
			@include f(42);
			letter-spacing: ls(630);
			display: inline-block;
			position: absolute;
			top: 55px;
			right: -53px;
			transform: rotate(90deg);
			z-index: 2; } }


	// .p-top-rsv__cont
	&__cont {
		position: relative;
		z-index: 3; }


	// .p-top-rsv__contitem
	&__contitem {
		// display: block
		display: none;
		@include pc {
			@include clearfix;
			// display: flex
 }			// justify-content: space-between

		&.is-show {
			display: block; }

		// .p-top-rsv__contitem--roasted
		&--roasted {
			.c-hdl:before {
				background: url('../img/common/apb/R-100.png') no-repeat;
				background-size: 100% 100%;
				width: 76px;
				height: 76px;
				top: -18px;
				left: -30px;
				@include pc {
					width: 110px;
					height: 110px;
					top: 0;
					left: 0; } }

			.p-top-step__ttl:after {
				@include pc {
					width: calc(100% - 118px); } } }


		// .p-top-rsv__contitem--personal
		&--personal {
			.c-hdl:before {
				background: url('../img/common/apb/P-100.png') no-repeat;
				background-size: 100% 100%;
				width: 76px;
				height: 76px;
				top: -18px;
				left: -35px;
				@include pc {
					width: 110px;
					height: 110px;
					top: 0;
					left: 0; } } } }

	// .p-top-rsv__body
	&__body {
		margin: 45px 0 0;
		padding: 0 percentage(35 / widthSp(base));
		@include pc {
			float: left;
			padding: 0; } }

	.c-hdl {
		@include pc {
			padding: 25px 0 0 50px; }

		&--row2 {
			.en {
				letter-spacing: ls(260);
				@include pc {
					letter-spacing: ls(400); } } } }

	// .p-top-rsv__wrap
	&__wrap {
		@include pc {
			padding-left: 50px; } }

	// .p-top-rsv__read
	&__read {
		@include fz(14,28);
		letter-spacing: ls(120);
		margin: 36px auto 34px;
		@include pc {
			margin: 68px 0 73px; } }

	// .p-top-rsv__btn
	&__btn {
		padding: 0 percentage(35 / widthSp(base));
		@include pc {
			padding: 0 0 0 50px; } } }


.p-top-tab {
	// padding: 0 0 0 percentage(67.5 / widthSp(base))
	padding: 0;
	position: relative;
	@include pc {
		padding: 8px 0 0;
		width: percentage(540 / 1080);
 }		// padding: 8px percentage(50 / 980) 0


	@media screen and (min-width: 1120px) {
		width: percentage(460 / 1080);
		width: 460px; }

	// &::after
	// 	content: ''
	// 	display: block
	// 	width: percentage(100 / 375)
	// 	// width: calc(100% - 300px)
	// 	height: 100%
	// 	background-color: cl(white)
	// 	position: absolute
	// 	top: 0
	// 	left: 0
	// 	z-index: 1
	// 	+pc
	// 		display: none

	// .p-top-tab__list
	&__list {
		// display: flex
		@include pc {
			display: flex;
 } }			// width: percentage(363 / 880) !important

	// .p-top-tab__item
	&__item {
		position: relative;
		width: percentage(196 / 307.5);
		min-width: 196px;
		@include pc {
			// width: 50% !important
			width: auto;
			min-width: 0;
			margin-left: 20px; }

		&.swiper-slide-active,
		&.is-active {
			a {
				color: cl(black);

				&::before, &::after {
					opacity: 1; } } }

		a {
			@include f(16);
			letter-spacing: ls(220);
			text-align: center;
			color: cl(gray-a);
			padding: 4px 0 34px;
			position: relative;
			z-index: 1;
			@include alpha(.6);
			@include pc {
				letter-spacing: ls(260);
 }				// text-align: left

			&:before {
				content: '';
				display: block;
				width: 32px;
				height: 32px;
				border-radius: 50%;
				@include grad(90deg);
				@include center;
				bottom: auto;
				z-index: -1;
				opacity: 0; }

			&:after {
				content: '';
				display: block;
				width: 1px;
				height: 22px;
				background-color: cl(gray-c);
				position: absolute;
				@include center;
				top: auto;
				opacity: 0; } } } }


.p-top-step {
	width: 100%;
	padding-top: 10px;
	padding-left: percentage(38 / widthSp(base));
	z-index: 1;
	overflow: hidden;
	position: relative;
	@include pc {
		width: percentage(452 / 1080);
		float: right;
		padding-left: 0; }

	&:before {
		display: block;
		content: '';
		width: percentage(340 / widthSp(base));
		height: 126px;
		@include grad(90deg);
		position: absolute;
		top: 20px;
		right: 0;
		z-index: -1;
		@include pc {
			display: none; } }

	&:after {
		display: block;
		content: 'STEP';
		@include FT-L;
		@include f(34);
		letter-spacing: ls(630);
		display: inline-block;
		position: absolute;
		top: 3px;
		right: -28px;
		color: cl(gray-c);
		@include pc {
			display: none; } }


	// .p-top-step__ttl
	&__ttl {
		width: 100%;
		position: relative;
		margin-left: percentage(32 / widthSp(base));
		@include pc {
			width: percentage(300 / 452);
			margin-left: 12px; }

		&:after {
			display: none;
			@include pc {
				display: block;
				content: '';
				width: calc(100% - 190px);
				height: 1px;
				background-color: cl(gray-c);
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%); } }


		span {
			display: block;
			@include fz(16,21);
			letter-spacing: ls(80);
			position: relative;
			z-index: 1;
			@include pc {
				display: inline-block;
				padding-right: 15px; } } }

	// .p-top-step__list
	&__list {
		display: flex;
		margin: 30px auto 45px;
		overflow-x: scroll;
		@include pc {
			display: block;
			margin: 45px auto 0;
			overflow: visible; } }

	// .p-top-step__item
	&__item {
		padding: 20px 0 10px 12px;
		margin-left: percentage(20 / widthSp(base));
		position: relative;
		@include pc {
			padding: 0;
			margin-left: 0; }

		+ li {
			@include pc {
				margin-top: 20px; } }

		.img {
			width: 240px;
			@include pc {
				position: relative;
				width: percentage(312 / 452);
				padding-left: 12px; } }

		.num {
			width: 40px;
			height: 40px;
			position: absolute;
			top: 0;
			left: 0;
			@include pc {
				top: auto;
				bottom: 0;
				left: 0; } }

		p {
			@include fz(14,25);
			letter-spacing: ls(80);
			margin-top: 15px;
			padding-left: 0;
			@include pc {
				@include fz(14,21);
				margin-top: 5px;
				padding-left: 12px; } } } }


.p-top-blog {
	margin-top: 100px;
	overflow: hidden;
	@include pc {
		margin-top: 180px; }

	// .p-top-blog__head
	&__head {
		padding: 43px 0 140px;
		@include grad(90deg);
		@include pc {
			padding: 104px 0 160px; } }

	.c-hdl {
		padding: 30px 0 0;
		text-align: center;
		@include pc {
			padding: 30px 0 10px; }

		&:before {
			background: url('../img/common/apb/B-120.png') no-repeat;
			background-size: 100% 100%;
			@include center; }

		.en {
			@include fz(32,40);
			letter-spacing: ls(680);
			margin-left: 23px;
			@include pc {
				@include fzvw(42,53);
				letter-spacing: ls(880);
				margin-left: 30px; }
			@include wide {
				@include fz(42,53); } } }

	// .p-top-blog__wrap
	&__wrap {
		padding-left: percentage(35 / widthSp(base));
		@include pc {
			padding-left: 0; } }

	// .p-top-blog__list
	&__list {
		width: 100%;
		margin: -105px auto 0;
		display: flex;
		overflow-x: scroll;
		@include pc {
			overflow: visible;
			width: percentage(1080 / widthPc(base));
			max-width: 1080px;
			margin: -75px auto 0; } }

	// .p-top-blog__item
	&__item {
		width: 240px;
		min-width: 240px;
		@include pc {
			width: percentage(240 / 1080);
			min-width: 0; }

		+ li {
			margin-left: percentage(29 / 305);
			@include pc {
				margin-left: percentage(40 / 1080); } }

		&:last-child {
			width: 272.5px;
			min-width: 272.5px;
			padding-right: percentage(29 / 305);
			@include pc {
				width: percentage(240 / 1080);
				min-width: 0;
				padding-right: 0; } } }

	// .p-top-blog__link
	&__link {
		@include pc {
			&:hover {

				img {
					transform: scale(1.1, 1.1); }

				.cat {
					color: cl(black);
					background-color: cl(white); } } } }

	// .p-top-blog__thumb
	&__thumb {
		position: relative;

		.img {
			display: block;
			overflow: hidden;

			img {
				object-fit: cover;
				height: 135px;
				@include pc {
					@include easing(1.2); } } }

		.cat {
			@include FT-L;
			@include f(11);
			letter-spacing: ls(60);
			color: cl(white);
			background-color: cl(black-3);
			padding: 2px 20px;
			position: absolute;
			bottom: -6px;
			right: -6px;
			@include easing(1.2);
			@include pc {
				@include f(14);
				padding: 3px 25px;
				bottom: -8px;
				right: -8px; } } }

	// .p-top-blog__body
	&__body {
		margin-top: 14px;
		@include pc {
			margin-top: 17px; }

		.date {
			@include Lao;
			@include f(12);
			letter-spacing: ls(60);
			color: cl(gray-6);
			@include pc {
				@include f(13); } }

		.ttl {
			@include fz(16,32);
			letter-spacing: ls(60);
			margin-top: 2px;
			@include pc {
				@include fz(18,36);
				margin-top: 6px; } } }

	// .p-top-blog__more
	&__more {
		display: flex;
		justify-content: center;
		margin-top: 10px;
		@include pc {
			margin-top: 48px; }

		a {
			@include FT-B;
			@include f(15);
			letter-spacing: ls(450);
			padding: 11px 0;
			position: relative;
			@include easing();
			@include pc {
				padding: 8px 0;
				&:hover {
					color: cl(gray-a);

					&:before {
						background-color: cl(gray-a); } } }

			&:before {
				content: '';
				display: block;
				width: calc(100% - 5px);
				height: 1px;
				background-color: cl(black);
				position: absolute;
				bottom: 0;
				left: 0;
				@include easing(); } } } }









