// ============================================================
// HEAD
// ============================================================
// .l-head
// --------------------------------------------------
.l-head {
	position: relative;
	padding-top: 89px;
	padding-bottom: 27px;
	overflow: hidden;
	@include pc {
		padding-top: 113px;
		padding-bottom: 366px; }

	&:before {
		content: '';
		width: percentage(340 / widthSp(base));
		height: 232px;
		@include grad(90deg);
		position: absolute;
		bottom: 27px;
		left: 0;
		@include pc {
			width: calc(100% - 100px);
			height: 400px;
			bottom: 0; } }

	// .l-head__inner
	&__inner {
		width: 100%;
		margin: 0 auto;
		position: relative;
		padding: 0 percentage(35 / widthSp(base)) 295px;
		@include pc {
			width: percentage(1080 / widthPc(base));
			max-width: 1080px;
			padding: 0; } }


	// .l-head__ttl
	&__ttl {

		span {
			display: block;
			font-weight: 400; }

		.en {
			@include FT-M;
			@include f(32);
			letter-spacing: ls(475);
			@include pc {
				@include f(49);
				letter-spacing: ls(450); } }

		.ja {
			@include f(14);
			letter-spacing: ls(120);
			color: cl(gray-a);
			margin-top: 8px;
			@include pc {
				@include f(16);
				letter-spacing: ls(160);
				margin-top: 12px; } } }

	// .l-head__txten
	&__txten {
		position: absolute;
		top: -43px;
		right: 7px;
		@include pc {
			position: relative;
			top: auto;
			right: auto;
			display: flex;
			justify-content: flex-end;
			margin-top: 18px;
			margin-right: percentage(-70 / widthPc(base)); }

		img {
			width: 54px;
			height: auto;
			@include pc {
				width: auto;
				height: 110px; } } }

	// .l-head__accent
	&__accent {
		display: none;
		width: 91px;
		position: absolute;
		top: 162px;
		left: 0;
		@include pc {
			display: block; } }

	.c-clock {
		position: absolute;
		bottom: 0;
		right: percentage(35 / widthSp(base));
		@include pc {
			top: 420px;
			bottom: auto;
			left: auto;
			right: 68px;
			writing-mode: vertical-rl;
			padding-left: 0;
			padding-top: 28px;

			&:before {
				top: 0;
				left: 53%;
				transform: translateX(-50%); } } }


	// .l-head--rsv
	&--rsv {
		@include pc {
			padding-bottom: 100px; }

		&:before {
			height: calc(100% - 251px);
			@include pc {
				height: 400px; } }

		.l-head__inner {
			padding: 0 percentage(35 / widthSp(base)) 55px;
			@include pc {
				padding: 0; } }

		.l-head__txten {
			@include pc {
				margin-right: percentage(-170 / widthPc(base)); } } }

	// .l-head--blog
	&--blog {
		@include pc {
			padding-bottom: 350px; }

		.l-head__txten {
			@include pc {
				margin-top: 13px;
				margin-right: percentage(48 / widthPc(base)); } }

		.c-clock {
			@include pc {
				top: 368px; } } } }

// .l-head-nav
// --------------------------------------------------
.l-head-nav {
	margin: 112px auto 0;
	@include pc {
		margin: 80px auto 0; }

	// .l-head-nav__list
	&__list {
		display: block;
		@include pc {
			display: flex;
			padding: 0 percentage(110 / 1080); } }

	// .l-head-nav__item
	&__item {
		width: percentage(270 / 305);
		@include pc {
			width: percentage(300 / 860); }

		+ .l-head-nav__item {
			margin-top: 47px;
			@include pc {
				margin-top: 0;
				margin-left: percentage(140 / 860); } } }

	// .l-head-nav__ttl
	&__ttl {
		@include f(18);
		letter-spacing: ls(230);
		padding: 8px 25px 8px 0;
		border-bottom: 1px solid cl(gray-c);
		position: relative;
		font-weight: 600;
		@include easing();
		@include pc {
			@include f(20);
			&:hover {
				color: cl(gray-a); } }

		&:after {
			content: '';
			display: block;
			width: 4px;
			height: 17px;
			background: url('../img/common/ico_arr.svg') no-repeat;
			background-size: 100% 100%;
			position: absolute;
			top: 50%;
			right: 7px;
			transform: translateY(-50%);
			@include pc {
				right: 14px; } } }

	// .l-head-nav__txt
	&__txt {
		@include fz(13,26);
		letter-spacing: ls(120);
		margin-top: 17px;
		@include pc {
			@include fz(14,28); } }

	// .l-head-nav__modalbtn
	&__modalbtn {
		@include f(14);
		letter-spacing: ls(500);
		padding: 3px 0;
		position: relative;
		margin-top: 24px;
		@include pc {
			padding: 2px 0;
			margin-top: 32px;
			cursor: pointer;

			&:hover {
				.icon {
					background-color: cl(gray-c); } } }

		&:before {
			content: '';
			// width: 95px
			width: calc(100% - 175px);
			height: 1px;
			background-color: cl(gray-c);
			position: absolute;
			top: 50%;
			right: 44px;
			transform: translateY(-50%);
			@include pc {
				// width: 110px
				width: calc(100% - 190px);
				right: 50px; } }

		.icon {
			display: block;
			width: 28px;
			height: 28px;
			border-radius: 50%;
			border: 1px solid cl(gray-c);
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			@include easing;
			@include pc {
				width: 25px;
				height: 25px; }

			&:before,
			&:after {
				content: '';
				background-color: cl(black);
				position: absolute;
				top: 50%;
				right: 0;
				left: 0;
				margin: auto;
				transform: translateY(-50%); }

			&:before {
				width: 10px;
				height: 1px;
				@include pc {
					width: 9px; } }

			&:after {
				width: 1px;
				height: 10px;
				@include pc {
					height: 9px; } } } } }
