// ============================================================
// font-family
// ============================================================
// font-family
//--------------------------------------------------
@import url('https://fonts.googleapis.com/css2?family=GFS+Didot&display=swap');

@font-face {
	font-family: "futura-book";
	src: url("../fonts/futura-book.woff") format("woff"); }

@font-face {
	font-family: "futura-medium";
	src: url("../fonts/futura-medium.woff") format("woff"); }

@font-face {
	font-family: "futura-light";
	src: url("../fonts/futura-light.woff") format("woff"); }

@font-face {
	font-family: "didot";
	src: url("../fonts/didot.woff") format("woff"); }

@font-face {
	font-family: "lao-mn";
	src: url("../fonts/lao-mn.woff") format("woff"); }

@mixin JPfont {
	font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', sans-serif;
	font-weight: 500; }

// Futura Book
@mixin FT-B {
	font-family: "futura-book", "Trebuchet MS", "Arial", "Century Gothic", sans-serif;
	font-weight: 300; }

// Futura Medium
@mixin FT-M {
	font-family: "futura-medium", "Trebuchet MS", "Arial", "Century Gothic", sans-serif;
	font-weight: 400; }

// Futura Medium
@mixin FT-L {
	font-family: "futura-light", "Trebuchet MS", "Arial", "Century Gothic", sans-serif;
	font-weight: 100; }

// Lao MN
@mixin Lao {
	font-family: "lao-mn", serif;
	font-weight: 400; }

// Didot
@mixin Did {
	font-family: "didot", 'GFS Didot', serif; }
