// .c-form
// --------------------------------------------------
.c-form {
	$this: &;

	// .c-form__unit
	&__unit {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		position: relative;
		padding: 0 percentage(33 / widthSp(base));
		@include pc {
			padding: 0; } }

	// .c-form__term
	&__term {
		width: 100%;
		@include fz(14,28);
		letter-spacing: ls(50);
		@include pc {
			width: 165px;
			@include fz(16,30); } }

	// .c-form__input
	&__input {
		width: 100%;
		@include JPfont;
		@include fz(14,28);
		letter-spacing: ls(50);
		margin-top: 10px;
		position: relative;
		@include pc {
			max-width: 360px;
			@include fz(16,32);
			margin-top: 0; }

		#{$this}--completion & {
			color: cl(red); }

		// .c-form__input--sm
		&--sm {
			width: 200px;
			@include pc {
				width: 120px; } }

		// .c-form__input--md
		&--md {
			width: 200px;
			@include pc {
				width: 200px; } }

		input[type="radio"] {
			position: absolute;
			overflow: hidden;
			clip: rect(0, 0, 0, 0);
			margin: -1px;
			padding: 0;
			width: 1px;
			height: 1px;
			border: 0;

			&:checked + label {
				&::before {
					background: cl(white); }

				&::after {
					content: '';
					display: block;
					width: 12px;
					height: 12px;
					border-radius: 50%;
					background: cl(black-3);
					position: absolute;
					top: 4px;
					left: 4px; } } }

		label {
			position: relative;
			margin-right: 28px;
			padding: 2px 0 2px 32px;
			cursor: pointer;

			&::before {
				content: '';
				display: block;
				width: 20px;
				height: 20px;
				border: 1px solid cl(gray-9);
				border-radius: 50%;
				position: absolute;
				top: 0;
				left: 0; } } }

	// .c-form__select
	&__select {
		position: relative;

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 4px 6px 4px;
			border-color: transparent transparent cl(gray-9) transparent;
			top: 50%;
			right: 8px;
			z-index: 1; }

		&::before {
			transform: translateY(-5px); }

		&::after {
			transform: translateY(5px) rotate(180deg); }

		select {
			width: 100%;
			@include fz(14,28);
			@include JPfont;
			color: cl(text);
			background-color: cl(white);
			display: block;
			box-shadow: none;
			border-radius: 0;
			border: none;
			border-bottom: 1px solid cl(gray-c);
			padding: 5px 0;
			position: relative;
			outline: none;
			appearance: none;
			cursor: pointer;
			@include alpha();
			@include pc {
				@include fz(16,32); }

			&::-ms-expand {
				display: none; } }

		// .c-form__select--gray
		&--gray {
			select {
				background-color: cl(gray-f7); } } } }

