// ============================================================
// OTHER FOOT
// ============================================================
// .c-other-foot
// --------------------------------------------------
.c-other-foot {
	margin-top: 180px;
	border-top: 1px solid cl(gray-c);
	display: flex;
	padding-top: 110px;

	// .c-other-foot__label
	&__label {
		@include FT-B;
		@include f(16);
		letter-spacing: ls(430);
		color: cl(gray-a);
		width: percentage(300 / 1120);
		margin-right: percentage(20 / 1120);
		margin-top: 10px; }

	// .c-other-foot__body
	&__body {
		width: percentage(800 / 1120); }

	// .c-other-foot__hdl
	&__hdl {
		@include fz(26,42);
		letter-spacing: ls(120); }

	// .c-other-foot__txt
	&__txt {
		@include fz(16,32);
		letter-spacing: ls(120);
		margin-top: 45px; }

	// .c-other-foot__signature
	&__signature {
		@include fz(14,28);
		letter-spacing: ls(120);
		text-align: right;
		margin-top: 30px; } }
