// ============================================================
// INFO
// ============================================================
.c-info {

	// .c-info__inner
	&__inner {
		display: block;
		width: 100%;
		margin: 0 auto;
		@include pc {
			display: flex;
			width: percentage(1248 / widthPc(base)); } }

	// .c-info__img
	&__img {
		width: 100%;
		padding-left: percentage(35 / widthSp(base));
		@include pc {
			width: percentage(540 / 1248);
			padding-left: 0;
			box-shadow: -2px 2px 6px 2px rgba(cl(black), .25); } }

	// .c-info__body
	&__body {
		width: 100%;
		margin: 0 auto;
		padding: 50px percentage(35 / widthSp(base)) 0;
		@include pc {
			width: percentage(536 / 1248);
			margin: 58px 0 0 percentage(35 / 1248);
			padding: 0; } }

	.c-hdl {
		@include pc {
			padding: 25px 0 0 40px; }

		&:before {
			background: url('../img/common/apb/S-100.png') no-repeat;
			background-size: 100% 100%;
			width: 76px;
			height: 76px;
			top: -18px;
			left: -35px;
			@include pc {
				width: 110px;
				height: 110px;
				top: 0;
				left: 0; } }

		.en {
			letter-spacing: ls(480); } }

	// .c-info__detail
	&__detail {
		@include f(14);
		letter-spacing: ls(120);
		margin: 41px 0 45px 0;
		@include pc {
			@include clearfix;
			margin: 70px 0 55px 52px; }

		dt {
			width: 100%;
			color: cl(gray-a);
			margin-bottom: 6px;
			@include pc {
				width: 94px;
				margin-bottom: 0;
				display: inline-block;
				float: left; } }

		dd {
			margin-left: auto;
			margin-bottom: 26px;
			@include pc {
				display: block;
				margin-bottom: 0;
				margin-left: 94px; }

			&:not(:last-child) {
				@include pc {
					margin-bottom: 22px; } } } }

	.c-btn {
		@include pc {
			margin-left: 40px; } } }

