// ============================================================
// OTHER
// ============================================================
// .l-other
// --------------------------------------------------
.l-other {
	background-color: cl(gray-f4); }

// .l-other-head
// --------------------------------------------------
.l-other-head {
	padding: 50px percentage(80 / widthPc(base)) 0;

	&__hdl {
		@include fz(32,42);
		letter-spacing: ls(120);
		font-weight: 500;

		.small {
			display: block;
			@include fz(16,21);
			letter-spacing: ls(120);
			margin-top: 15px; } }

	&__read {
		@include fz(16,32);
		letter-spacing: ls(120);
		margin-top: 50px; }

	&__note {
		@include fz(14,28);
		letter-spacing: ls(120);
		color: cl(gray-a);
		margin-top: 6px; } }

