// ============================================================
// MIXIN
// ============================================================
// font-size, line-height
//--------------------------------------------------
@mixin f($size:font(base), $lh:$size+8) {
	font-size: $size * 1px;
	font-size: ( $size / 16 ) * 1rem;
	line-height: $lh / $size; }

@mixin fz($size:font(base), $lh:$size*1.5) {
	font-size: $size * 1px;
	font-size: ( $size / 16 ) * 1rem;
	line-height: $lh / $size; }

@mixin fzm($fz: font, $lh: $fz * 2, $mb: 0, $mt: 0) {
	font-size: ($fz / 16) * 1rem;
	line-height: $lh / $fz;
	margin-bottom: $mb - ($fz * ($lh / $fz) - $fz) / 2 + px;
	margin-top: $mt - ($fz * ($lh / $fz) - $fz) / 2  + px; }

@mixin fzvw($size:font(base), $lh:$size*1.5) {
	font-size: ($size / 1280) * 100 + vw;
	line-height: $lh / $size; }


// letter-spacing, text-align
//--------------------------------------------------
@mixin lsCenter($size:.1em) {
	text-align: center;
	letter-spacing: $size; }


// Metrics
//--------------------------------------------------
@mixin metrics($bool:true) {
	@if $bool == true {
		font-feature-settings : "palt" 1; }
	@if $bool == false {
		font-feature-settings: normal; } }


// Media Query
//--------------------------------------------------
@mixin mq320 {
	@media screen and (max-width: 320px) {
		@content; } }

@mixin sp {
	@media screen and (max-width: 414px) {
		@content; } }

@mixin tab {
	@media screen and (min-width: 768px) {
		@content; } }

@mixin pc {
	@media screen and (min-width: 961px) {
		@content; } }

@mixin wide {
	@media screen and (min-width: 1281px) {
		@content; } }

// 縦向き
@mixin portrait {
	@media screen and (orientation: portrait) {
		@content; } }

// 横向き
@mixin landscape {
	@media screen and (orientation: landscape) {
		@content; } }


// Block
//--------------------------------------------------
@mixin content {
	max-width: widthPc(base) + px;
	width: 100%;
	margin: 0 auto; }

@mixin section {
	@include content;
	padding-left: percentage($inner-padding-sp / widthSp(base));
	padding-right: percentage($inner-padding-sp / widthSp(base));
	@include pc {
		padding-left: $inner-padding-pc + px;
		padding-right: $inner-padding-pc + px; } }


// FlexBox
//--------------------------------------------------
@mixin flex {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: stretch; }

@mixin flex-end {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	align-items: stretch; }


// Position
//--------------------------------------------------
@mixin center {
	margin: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0; }

@mixin left {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0; }

@mixin relative($zPosition: 1) {
	position: relative;
	z-index: $zPosition; }


// Hover
//--------------------------------------------------
@mixin alpha($alpha:.7) {
	@include easing;
	@include pc {
		&:hover {
			opacity: $alpha; } } }

@mixin deco {
	// color: cl(main)
	text-decoration: underline;
	@include pc {
		&:hover {
			text-decoration: none; } } }

@mixin hoverTxt($color: #fff, $alpha:.3) {
	@include easing;
	@include pc {
		&:hover {
			color: rgba($color, $alpha); } } }


// Transition
//--------------------------------------------------
@mixin easing($duration: .5, $ease: easing(ease-out-expo)) {
	transition: $duration + s $ease; }


// Clearfix
//--------------------------------------------------
@mixin clearfix {
	&:after {
		content: '';
		clear: both;
		display: block;
		height: 0; } }


// background-size: cover
//--------------------------------------------------
@mixin bgCover($url,$bs:cover) {
	background-image: url($url);
	background-size: $bs;
	background-repeat: no-repeat;
	background-position: 50% 50%; }


// grad
//--------------------------------------------------
@mixin grad($drection:0deg) {
	.hour-04-10 & {
		background-image: linear-gradient($drection, #f2feff, #fffde0); }

	.hour-10-15 & {
		background-image: linear-gradient($drection, #dcf1fa, #fafafa); }

	.hour-15-20 & {
		background-image: linear-gradient($drection, #e3eeff, #f3e7e9); }

	.hour-20-04 & {
		background-image: linear-gradient($drection, #c8cccc, #f5faff); } }


// // font deco
// //--------------------------------------------------
// =fDeco
// 	background-image: url($url)
// 	background-size: $bs
// 	background-repeat: no-repeat
// 	background-position: 50% 50%


// グラデーション ホバー
//--------------------------------------------------
// =hvr-grad
// 	overflow: hidden
// 	+pc
// 		&:hover
// 			.border-ht
// 				left: 0
// 			.border-hb
// 				right: 0
// 			.border-vl
// 				top: 0
// 			.border-vr
// 				bottom: 0

// 	.border-h,.border-v
// 		position: absolute
// 		display: block
// 		width: 100%
// 		height: 100%
// 		top: 0
// 		left: 0
// 	.border-h
// 		> *
// 			position: absolute
// 			display: block
// 			width: 100%
// 			height: 2px
// 			background: linear-gradient(to right,cl(cyan) 0%, cl(blgr) 100%)
// 			+easing
// 	.border-v
// 		> *
// 			position: absolute
// 			display: block
// 			width: 2px
// 			height: 100%
// 			+easing
// 	.border-ht
// 		top: 0
// 		left: -101%
// 	.border-hb
// 		bottom: 0
// 		right: -101%
// 	.border-vl
// 		background: cl(cyan)
// 		top: -101%
// 		left: 0
// 	.border-vr
// 		background: cl(blgr)
// 		bottom: -101%
// 		right: 0


