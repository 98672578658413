// ============================================================
// RECOMMEND
// ============================================================
.l-recommend {
	width: 100%;
	margin: 0 auto;
	position: relative;
	@include pc {
		background-color: cl(gray-f7); }

	&:before {
		content: '';
		display: block;
		width: percentage(160 / widthSp(base));
		height: 100%;
		background-color: cl(gray-f7);
		position: absolute;
		top: 0;
		left: 0;
		@include pc {
			display: none; } }


	// .l-recommend__inner
	&__inner {
		width: 100%;
		margin: 0 auto;
		padding: 80px 0 72px percentage(70 / widthSp(base));
		display: block;
		position: relative;
		@include pc {
			width: percentage(1080 / widthPc(base));
			max-width: 1080px;
			padding: 120px 0 80px;
			display: flex; } }

	h2 {
		@include FT-B;
		@include f(16);
		letter-spacing: ls(260);
		height: auto;
		padding-bottom: 97px;
		writing-mode: vertical-rl;
		margin: 0 auto;
		position: absolute;
		top: 78px;
		left: percentage(28 / widthSp(base));
		@include pc {
			@include f(20);
			letter-spacing: ls(280);
			margin: 0 percentage(56 / 1080) 0 0;
			padding-bottom: 0;
			padding-left: 15px;
			height: 290px;
			border-left: 1px solid cl(gray-c);
			position: relative;
			top: auto;
			left: auto; }

		&:after {
			content: '';
			display: block;
			width: 1px;
			height: 90px;
			background-color: cl(gray-c);
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			@include pc {
				display: none; } } }

	// .l-recommend__list
	&__list {
		width: 100%;
		display: block;
		@include pc {
			width: percentage(980 / 1080);
			display: flex;
			flex-flow: row nowrap; } }

	// .l-recommend__item
	&__item {
		width: 100%;
		@include pc {
			width: percentage(300 / 980); }

		+ .l-recommend__item {
			margin-top: 72px;
			@include pc {
				margin-top: 0;
				margin-left: percentage(40 / 980); } } }

	// .l-recommend__link
	&__link {
		@include pc {
			&:hover {

				img {
					transform: scale(1.1, 1.1); }

				.cat {
					color: cl(black);
					background-color: cl(white); } } } }

	// .l-recommend__thumb
	&__thumb {
		position: relative;

		.img {
			display: block;
			overflow: hidden;

			img {
				object-fit: cover;
				height: 172px;
				@include pc {
					height: 168px;
					@include easing(1.2); } } }

		.cat {
			@include FT-L;
			@include f(11);
			letter-spacing: ls(60);
			color: cl(white);
			background-color: cl(black-3);
			padding: 2px 20px;
			position: absolute;
			bottom: -6px;
			right: 0;
			@include easing(1.2);
			@include pc {
				@include f(14);
				padding: 3px 25px;
				bottom: -8px;
				right: -8px; } } }

	// .l-recommend__body
	&__body {
		margin-top: 12px;
		@include pc {
			margin-top: 28px; }

		.date {
			@include Lao;
			@include f(12);
			letter-spacing: ls(60);
			color: cl(gray-6);
			@include pc {
				@include f(13); } }

		.ttl {
			@include fz(16,32);
			letter-spacing: ls(60);
			margin-top: 2px;
			@include pc {
				@include fz(18,36);
				margin-top: 6px; } } } }
