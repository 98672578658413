// ============================================================
// BEANS
// ============================================================
// .c-beans
// --------------------------------------------------
.c-beans {
	$this: &;
	display: block;
	margin: 48px auto 83px;
	@include pc {
		margin: 90px auto 120px; }

	h3 {
		display: block;
		font-weight: 500;
		margin: 0 auto 35px;
		@include pc {
			margin: 0 auto 55px; }

		span {
			display: block;
			text-align: center; }

		.ja {
			@include fz(20);
			letter-spacing: ls(120);
			font-weight: 600;
			@include pc {
				@include fz(24); } }

		.en {
			@include FT-L;
			@include fz(13);
			letter-spacing: ls(80);
			margin-top: 6px;
			@include pc {
				@include fz(15);
				margin-top: 3px; } } }

	// .c-beans__list
	&__list {
		display: block;
		width: percentage(305 / widthSp(base));
		margin: 0 auto;
		@include pc {
			display: flex;
			flex-flow: row wrap;
			width: percentage(1110 / widthPc(base));
			max-width: 1110px; } }

	// .c-beans__item
	&__item {
		width: 100%;
		margin: 70px auto 0;
		position: relative;
		@include alpha(.8);
		cursor: pointer;
		@include pc {
			width: percentage(340 / 1110);
			margin: 80px percentage(15 / 1110) 0; }

		&:first-child {
			margin-top: 0; }

		&:nth-child(-n+3) {
			@include pc {
				margin-top: 0; } }

		&:hover {
			#{$this}__body {
				.price {
					color: cl(black-3); } } } }

	// .c-beans__new
	&__new {
		width: 60px;
		height: 60px;
		position: absolute;
		top: 16px;
		left: -16px;
		z-index: 1;
		@include pc {
			top: 12px;
			left: -8px; } }

	// .c-beans__thumb
	&__thumb {
		position: relative;

		&::before {
			content: '';
			width: calc(100% - 16px);
			height: calc(100% - 16px);
			background-color: rgba(cl(white), .6);
			@include center;
			@include easing;
			opacity: 0; }

		&::after {
			content: 'SELECTED';
			width: 100%;
			height: 100%;
			@include FT-B;
			@include fz(23,80);
			letter-spacing: ls(420);
			color: cl(black-3);
			display: flex;
			align-items: center;
			justify-content: center;
			@include center;
			@include easing;
			opacity: 0; }

		.is-active & {
			&::after,&::before {
				opacity: 1; } }

		img {
			width: 100%;
			max-height: 228px;
			object-fit: cover;
			font-family: 'object-fit: contain;';
			@include pc {
				height: 255px;
				max-height: none; } } }

	// .c-beans__body
	&__body {
		margin-top: 20px;
		@include pc {
			margin-top: 15px; }

		.ttl {
			@include fz(16,21);
			letter-spacing: ls(120);
			color: cl(black-3);
			font-weight: 600;
			@include pc {
				@include fz(18,36); } }

		.read {
			@include fz(13,26);
			letter-spacing: ls(60);
			color: cl(black-3);
			margin-top: 13px;
			@include pc {
				@include fz(14,28);
				margin-top: 5px; } }

		.price {
			@include fz(13,26);
			letter-spacing: ls(60);
			color: cl(gray-a);
			margin-top: 10px;
			@include easing;
			@include pc {
				@include fz(14,28);
				margin-top: 15px; }

			.is-active & {
				color: cl(black-3); } } }

	// .c-beans__btn
	&__btn {
		width: 100%;
		height: 50px;
		@include fz(14,28);
		letter-spacing: ls(520);
		text-align: center;
		color: cl(white);
		background-color: cl(black-3);
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 17px auto 0;
		@include easing;
		@include pc {
			width: percentage(300 / 340);
			height: 48px;
			margin: 25px auto 0; }

		.is-active & {
			color: cl(black-3);
			background-color: cl(gray-c); } } }


