// ============================================================
// HEADER
// ============================================================
// .l-header
// --------------------------------------------------
.l-header {
	display: block;
	padding: 25px 35px 0;
	position: relative;
	@include pc {
		padding: 50px percentage(100 / widthPc(base)) 40px; }

	// &:before
	// 	content: ''
	// 	width: 50%
	// 	height: 680px
	// 	+grad(-180deg)
	// 	position: absolute
	// 	top: 0
	// 	right: 0
	// 	z-index: -1
	// 	+pc
	// 		width: percentage(220 / widthPc(base))
	// 		height: 656px
	// 		left: 0
	// 		right: auto

	// .l-header__inner
	&__inner {
		width: 100%;
		margin: 0 auto;
		@include pc {
			max-width: 1080px; } }

	// .l-header__logo
	&__logo {
		width: 80px;
		position: relative;
		z-index: 1;
		@include pc {
			width: 109px;
			margin: 0 auto;
			@include alpha(.6); } } }


// .l-header-other
// --------------------------------------------------
.l-header-other {
	background-color: cl(gray-f4);
	padding: 60px percentage(80 / widthPc(base));
	display: flex;
	align-items: center;

	// .l-header-other__label
	&__label {
		@include FT-B;
		@include f(16);
		letter-spacing: ls(430);
		color: cl(gray-a); }

	// .l-header-other__logo
	&__logo {
		width: 80px;
		position: relative;
		z-index: 1;
		@include pc {
			width: 109px;
			margin-left: auto;
			@include alpha(); } } }


// .l-header-rsv
// --------------------------------------------------
.l-header-rsv {
	padding: 24px percentage(35 / widthSp(base));
	@include pc {
		padding: 60px percentage(150 / widthPc(base)); }

	// .l-header-rsv__logo
	&__logo {
		width: 80px;
		margin-left: auto;
		position: relative;
		z-index: 1;
		@include pc {
			width: 109px;
			@include alpha(); } } }
