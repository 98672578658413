// ============================================================
// SHARE
// ============================================================
.c-share {
	width: 140px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 11px;
	border-bottom: 1px solid cl(gray-c);
	@include pc {
		width: 160px;
		padding-bottom: 12px; }

	&__head {
		@include FT-B;
		@include fz(14,24);
		letter-spacing: ls(260);
		@include pc {
			@include fz(16,28); } }

	ul {
		font-size: 0; }

	li {
		display: inline-block;

		+ li {
			margin-left: 10px;
			@include pc {
				margin-left: 14px; } }

		a {
			width: 23px;
			height: 23px;
			position: relative;
			@include easing;
			@include pc {
				width: 28px;
				height: 28px;
				&:hover {
					svg {
						fill: cl(black); } } } }

		svg {
			width: 23px;
			height: 23px;
			fill: cl(gray-c);
			@include center;
			@include easing; } } }


