// ============================================================
// BLOG
// ============================================================
// .p-blog
// --------------------------------------------------
.p-blog {

	// .p-blog__cont
	&__cont {
		width: 100%;
		margin: 47px auto 100px;
		display: block;
		@include pc {
			width: percentage(1160 / widthPc(base));
			max-width: 1160px;
			margin: 100px auto 200px;
			display: flex; } }

	// .p-blog__morebtn
	&__morebtn {
		// display: flex
		// justify-content: center
		display: block;
		margin: 83px percentage(105 / 305) 0 percentage(62 / 305);
		// padding-right: percentage(62 / 305)
		@include pc {
			margin: 132px auto 0;
 }			// padding-right: 0

		span {
			@include FT-B;
			@include f(15);
			letter-spacing: ls(450);
			padding: 11px 0;
			position: relative;
			display: block;
			@include easing();
			@include pc {
				padding: 8px 0;

				&:hover {
					color: cl(gray-a);

					&:before {
						background-color: cl(gray-a); } } }

			&:before {
				content: '';
				display: block;
				width: calc(100% - 5px);
				height: 1px;
				background-color: cl(black);
				position: absolute;
				bottom: 0;
				left: 0; } } } }


// .p-blog-cat
// --------------------------------------------------
.p-blog-cat {
	width: percentage(307 / widthSp(base));
	margin: 0 auto 40px;
	position: relative;
	@include pc {
		width: percentage(180 / 1160);
		margin: 0 0 0 percentage(-20 / 1160); }

	&.is-open {

			.line--r {
				transform: rotate(0); }

			.p-blog-cat__list {
				display: block; } }

	// .p-blog-cat__inner
	&__inner {
		width: 100%;
		position: relative;
		@include pc {
			width: percentage(180 / 180);
			padding-left: 25px;
			padding-top: 40px;
			position: sticky;
			top: 0; } }

	// .p-blog-cat__btn
	&__btn {
		position: relative;
		padding: 3px;
		pointer-events: visible;
		@include pc {
			position: absolute;
			top: 48px;
			left: -4px;
			padding: 0;
			pointer-events: none; }

		&:before {
			content: '';
			display: block;
			width: calc(100% - 168px);
			height: 1px;
			background-color: cl(gray-c);
			position: absolute;
			top: 50%;
			right: 44px;
			transform: translateY(-50%);
			@include pc {
				display: none; } }

		span {
			display: block; }

		.txt {
			@include FT-B;
			@include f(15);
			letter-spacing: ls(320);
			color: cl(text);
			@include pc {
				@include f(11);
				color: cl(gray-a);
				writing-mode: vertical-rl; } }

		.ico {
			display: block;
			width: 28px;
			height: 28px;
			border-radius: 50%;
			border: 1px solid cl(gray-c);
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			@include pc {
				display: none; } }

		.line {
			display: block;
			width: 10px;
			height: 1px;
			background-color: cl(black);
			@include center;
			@include easing;

			&--l {}

			&--r {
				transform: rotate(-90deg); } } }

	// .p-blog-cat__list
	&__list {
		position: relative;
		padding: 0 2px;
		margin-top: 5px;
		display: none;
		@include pc {
			padding: 0;
			margin-top: 0;
			display: block; }

		&:before {
			content: '';
			width: 1px;
			height: 100%;
			background-color: cl(gray-c);
			position: absolute;
			top: 0;
			left: 7px; } }

	// .p-blog-cat__item
	&__item {
		padding-left: 30px;
		position: relative;
		@include pc {
			padding-left: 28px; }

		&:before {
			content: '';
			width: 12px;
			height: 12px;
			border-radius: 50%;
			@include grad;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%) rotate(90deg);
			opacity: 0;
			@include easing;
			@include pc {
				width: 15px;
				height: 15px; } }

		+ .p-blog-cat__item {
			margin-top: 7px;
			@include pc {
				margin-top: 6px; } }

		&.is-current {
			&:before {
				opacity: 1; }
			a {
				@include FT-B;
				color: cl(black);
				pointer-events: none; } }

		a {
			@include FT-L;
			@include f(13);
			color: cl(gray-a);
			padding: 3px;
			display: inline-block;
			@include pc {
				@include f(15); } } } }


// .p-blog-main
// --------------------------------------------------
.p-blog-main {
	width: percentage(305 / widthSp(base));
	margin-left: auto;
	position: relative;
	@include pc {
		width: percentage(980 / 1160);
		margin-left: percentage(20 / 1160);
		margin-top: 40px; }

	// .p-blog-main__catfixed
	&__catfixed {
		@include FT-B;
		@include f(14);
		letter-spacing: ls(320);
		text-transform: uppercase;
		color: cl(gray-c);
		padding-top: 62px;
		position: absolute;
		top: 40px;
		left: percentage(-38 / 305);
		display: inline-block;
		display: block;
		writing-mode: vertical-rl;
		@include pc {
			display: none; }

		&:before {
			content: '';
			display: block;
			width: 1px;
			height: 50px;
			background-color: cl(gray-c);
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%); } }

	.page-load-status {
		margin-top: 70px;
		@include pc {
			margin-top: 100px; } }

	.infinite-scroll-request,
	.infinite-scroll-last,
	.infinite-scroll-error {
		@include fz(14,28);
		letter-spacing: ls(120);
		text-align: left;
		@include pc {
			text-align: center; } } }


// .p-blog-new
// --------------------------------------------------
.p-blog-new {
	width: 100%;
	position: relative;
	@include pc {
		width: percentage(916 / 980); }

	// .p-blog-new__link
	&__link {
		display: block;
		@include pc {
			margin-right: 52px;

			&:hover {

				img {
					transform: scale(1.1, 1.1); }

				.cat {
					color: cl(black);
					background-color: cl(white); } } } }

	// .p-blog-new__thumb
	&__thumb {
		position: relative;

		.img {
			display: block;
			overflow: hidden;
			width: 100%;

			img {
				object-fit: cover;
				height: 172px;
				@include pc {
					height: 486px;
					@include easing(1.2); } } }

		.cat {
			@include FT-L;
			@include f(11);
			letter-spacing: ls(60);
			color: cl(white);
			background-color: cl(black-3);
			padding: 2px 20px;
			position: absolute;
			bottom: -6px;
			right: 0;
			@include easing(1.2);
			@include pc {
				@include f(15);
				padding: 3px 25px;
				bottom: -8px;
				right: -8px; } } }

	// .p-blog-new__body
	&__body {
		margin-top: 12px;
		@include pc {
			margin-top: 45px; }

		.date {
			@include Lao;
			@include f(12);
			letter-spacing: ls(60);
			color: cl(gray-6);
			@include pc {
				@include f(13); } }

		.ttl {
			@include fz(16,32);
			letter-spacing: ls(60);
			margin-top: 2px;
			@include pc {
				@include fz(20,40);
				margin-top: 4px; } } }

	// .p-blog-new__tag
	&__tag {
		display: none;
		@include pc {
			display: block;
			@include FT-B;
			@include f(19);
			letter-spacing: ls(350);
			writing-mode: vertical-rl;
			position: absolute;
			top: 0;
			right: 0; } } }


// .p-blog-list
// --------------------------------------------------
.p-blog-list {
	width: 100%;
	display: block;
	margin-top: 72px;
	@include pc {
		display: flex;
		flex-flow: row wrap;
		margin-top: 110px; }

	// .p-blog-list__item
	&__item {
		width: 100%;
		@include pc {
			width: percentage(448 / 980); }

		+ .p-blog-list__item {
			margin-top: 72px;
			@include pc {
				margin-top: 0; } }

		&:nth-child(n+3) {
			@include pc {
				margin-top: 90px; } }

		&:nth-child(even) {
			@include pc {
				margin-left: percentage(84 / 980); } } }

	// .p-blog-list__link
	&__link {
		@include pc {
			&:hover {

				img {
					transform: scale(1.1, 1.1); }

				.cat {
					color: cl(black);
					background-color: cl(white); } } } }

	// .p-blog-list__thumb
	&__thumb {
		width: 100%;
		position: relative;

		.img {
			display: block;
			overflow: hidden;
			width: 100%;

			img {
				object-fit: cover;
				height: 172px;
				@include pc {
					height: 252px;
					@include easing(1.2); } } }

		.cat {
			@include FT-L;
			@include f(11);
			letter-spacing: ls(60);
			color: cl(white);
			background-color: cl(black-3);
			padding: 2px 20px;
			position: absolute;
			bottom: -6px;
			right: 0;
			@include easing(1.2);
			@include pc {
				@include f(14);
				padding: 3px 25px;
				bottom: -8px;
				right: -8px; } } }

	// .p-blog-list__body
	&__body {
		padding: 0 15px 0 0;
		margin-top: 12px;
		@include pc {
			padding: 0;
			margin-top: 45px; }

		.date {
			@include Lao;
			@include f(12);
			letter-spacing: ls(60);
			color: cl(gray-6);
			@include pc {
				@include f(13); } }

		.ttl {
			@include fz(16,32);
			letter-spacing: ls(60);
			margin-top: 2px;
			@include pc {
				@include fz(18,36);
				margin-top: 6px; } } } }


