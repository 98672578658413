// ============================================================
// MENU
// ============================================================
// .l-menu
// --------------------------------------------------
.l-menu {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	overflow-y: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	z-index: -10;
	background-color: cl(white);
	@include pc {
		height: 100vh; }

	&::-webkit-scrollbar {
    display: none; }

	&.is-open {
		z-index: 100; }

	// .l-menu__inner
	&__inner {
		display: none;
		height: 100%;
		min-height: 560px;
		padding: percentage(35 /  widthSp(base)) percentage(35 / widthSp(base)) percentage(25 /  widthSp(base));
		position: relative;
		z-index: 1;
		@include pc {
			min-height: 700px;
			padding: percentage(74 /  widthPc(base)) percentage(100 / widthPc(base)) percentage(45 /  widthPc(base)); }

		&:before {
			content: '';
			width: percentage(308 / widthSp(base));
			height: calc(100% - 582px);
			min-height: 119px;
			@include grad(90deg);
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: -1;
			@include pc {
				width: percentage(440 / widthPc(base));
				height: 440px;
				min-height: 440px; } } }

	// .l-menu__list
	&__list {
		margin-top: 56px;
		@include pc {
			margin-top: 30px;
			margin-left: calc(50% - 45px); }

		li {
			position: relative;

			+ li {
				margin-top: 22px;
				@include pc {
					margin-top: 39px; } } }

		a {
			@include FT-M;
			@include f(24);
			letter-spacing: ls(250);
			color: cl(black);
			display: block;
			padding: 13px 0 0;
			@include easing();
			@include pc {
				@include f(32);
				letter-spacing: ls(280);
				padding: 28px 0 0;
				&:hover {
					color: cl(gray-a); } } }

		.ja {
			display: inline-block;
			@include f(14);
			letter-spacing: ls(120);
			color: cl(gray-a);
			margin-left: 10px;
			@include pc {
				display: none; } }

		.bg {
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1; }

		img {
			width: auto;
			height: 36px;
			@include pc {
				height: 64px; } } }

	// .l-menu__lower
	&__lower {
		position: absolute;
		left: percentage(35 / widthSp(base));
		bottom: 22px;
		@include pc {
			position: relative;
			left: auto;
			bottom: auto;
			margin-top: 80px; }

		li {
			+ li {
				margin-top: 0px;
				@include pc {
					margin-top: 8px; } } }

		a {
			@include FT-M;
			@include f(14);
			letter-spacing: ls(300);
			color: cl(black);
			display: inline-block;
			padding: 3px 0;
			@include easing();
			@include pc {
				&:hover {
					color: cl(gray-a); } } } }

	// .l-menu__bg
	&__bg {
		width: 100%;
		height: 100%;
		background: cl(white);
		position: absolute;
		top: 0;
		left: 0;
		@include pc {
			min-height: 700px; } } }



// .l-toggle
// --------------------------------------------------
.l-toggle {
	position: fixed;
	top: 28px;
	right: 35px;
	font-size: 0;
	letter-spacing: 0;
	z-index: 100;
	display: block;
	@include pc {
		top: 70px;
		right: percentage(100 / widthPc(base));
		&:hover {
			.l-toggle__line {
				background: cl(gray-a); } } }

	@include wide {
		right: calc(50% - 540px); }

	// .l-toggle__wrap
	&__wrap {
		width: 30px;
		height: 30px;
		position: relative;
		cursor: pointer;
		@include pc {
			width: 28px;
			height: 28px; } }

	// .l-toggle__line
	&__line {
		position: absolute;
		display: block;
		width: 30px;
		height: 1px;
		background: cl(black-3);
		top: calc(50% - 1px);
		left: 0;
		@include easing;
		@include pc {
			width: 28px;
			height: 2px; }

		&--top {
			transform: translateY(-6px);
			@include pc {
				transform: translateY(-8px); } }

		&--cen {}

		&--btm {
			transform: translateY(6px);
			@include pc {
				transform: translateY(8px); } } }

	&.is-open {

		.l-toggle__line--top {
			transform: rotate(45deg);
			top: calc(50% - 1px); }

		.l-toggle__line--cen {
			display: none; }

		.l-toggle__line--btm {
			transform: rotate(-45deg);
			top: calc(50% - 1px); } } }
